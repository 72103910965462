import React from "react";
import _ from "lodash";

import CountrySelector from "../../../common/CountrySelector";
import OSSelector from "../../../common/OSSelector";
import DeviceCategorySelector from "../../../common/DeviceCategorySelector";
import InventoryRegistrySelector from "components/common/InventoryRegistrySelector";
import BrowserSelector from "components/common/BrowserSelector";
import {
  getCatchallPricesLimit,
  getDetectorPricesLimit,
  getXPricesLimit,
  getPricesLimit,
  isPricesInvalid,
} from "./UPRSegmentationHelper";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const targetingBlockClass = "border p-2 my-2 bg-white";
const targetingTitleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

class NetworkUPRSegmentationForm extends React.Component {
  constructor(props) {
    super(props);

    let { rule } = props;
    const {
      id = "",
      name = "",
      optimization = "",
      targeting = {},
      detector_prices = [],
      catchall_prices = [],
      x_prices = [],
      prices = [],
    } = rule;

    this.state = {
      id,
      name,
      optimization,
      targeting,
      detector_prices,
      catchall_prices,
      prices,
      x_prices,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleOptimizationChanged = this.handleOptimizationChanged.bind(this);
    this.handleTargetingChanged = this.handleTargetingChanged.bind(this);
    this.handlePricesChanged = this.handlePricesChanged.bind(this);
    this.handleCatchallPricesChanged =
      this.handleCatchallPricesChanged.bind(this);
    this.handleDetectorPricesChanged =
      this.handleDetectorPricesChanged.bind(this);
    this.handleXPricesChanged = this.handleXPricesChanged.bind(this);
  }

  handleChange(e, key) {
    const value = e.target.value;
    this.setState({
      [key]: value,
    });

    let newRule = this.props.rule;
    newRule[key] = value;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleOptimizationChanged(newOpt) {
    this.setState({
      optimization: newOpt,
    });

    let newRule = this.props.rule;
    newRule.optimization = newOpt;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleDetectorPricesChanged(prices) {
    this.setState({
      detector_prices: prices,
    });

    let newRule = this.props.rule;
    newRule.detector_prices = prices;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleCatchallPricesChanged(prices) {
    this.setState({
      catchall_prices: prices,
    });

    // catchall prices is optional, don't save with empty array
    if (_.isEmpty(prices)) {
      let newRule = this.props.rule;
      newRule = _.omit(newRule, "catchall_prices");
      this.props.handleCurrentRuleChanged(newRule);
    } else {
      let newRule = this.props.rule;
      newRule.catchall_prices = prices;
      this.props.handleCurrentRuleChanged(newRule);
    }
  }

  handlePricesChanged(prices) {
    this.setState({
      prices,
    });

    let newRule = this.props.rule;
    newRule.prices = prices;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleXPricesChanged(prices) {
    this.setState({
      x_prices: prices,
    });

    // catchall prices is optional, don't save with empty array
    if (_.isEmpty(prices)) {
      let newRule = this.props.rule;
      newRule = _.omit(newRule, "x_prices");
      this.props.handleCurrentRuleChanged(newRule);
    } else {
      let newRule = this.props.rule;
      newRule.x_prices = prices;
      this.props.handleCurrentRuleChanged(newRule);
    }
  }

  handleTargetingChanged(newTargeting, key) {
    console.log(newTargeting, key);
    let { targeting } = this.state;
    targeting[key] = newTargeting;
    if (!newTargeting) {
      targeting = _.omit(targeting, key);
    }

    this.setState({
      targeting,
    });

    let newRule = this.props.rule;
    newRule.targeting = targeting;
    this.props.handleCurrentRuleChanged(newRule);
  }

  render() {
    const { networkId, networkInfo, modalType } = this.props;
    const {
      id,
      name,
      optimization,
      targeting,
      detector_prices,
      x_prices,
      prices,
      catchall_prices,
    } = this.state;

    return (
      <div>
        <div className="rounded mb-4 bg-white px-8 pt-6 pb-8">
          <div className="mb-4">
            <label className="block font-bold text-gray-800" htmlFor="ruleId">
              ID
            </label>
            <input
              className={modalType === "ADD" ? inputClass : inputClassDisabled}
              id="ruleId"
              type="number"
              value={id}
              onChange={(e) => this.handleChange(e, "id")}
              disabled={modalType !== "ADD"}
            />
          </div>

          <div className="mb-4">
            <label className="block font-bold text-gray-800" htmlFor="name">
              Name
            </label>
            <input
              className={inputClass}
              id="name"
              type="text"
              value={name}
              onChange={(e) => this.handleChange(e, "name")}
            />
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 mb-2 text-lg font-bold">
              Optimization
            </div>
            <div>
              <OptimizationControl
                optimization={optimization}
                handleOptimizationChanged={this.handleOptimizationChanged}
              ></OptimizationControl>
            </div>
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 text-lg font-bold">Targeting</div>

            <div className={targetingBlockClass}>
              <div className={targetingTitleClass}>OS</div>
              <TargetingWithInExControl
                targetingType={TARGETING_TYPE.OS}
                targeting={targeting[TARGETING_TYPE.OS]}
                handleTargetingChanged={(t) =>
                  this.handleTargetingChanged(t, TARGETING_TYPE.OS)
                }
              ></TargetingWithInExControl>
            </div>

            <div className={targetingBlockClass}>
              <div className={targetingTitleClass}>Device Category</div>

              <TargetingWithInExControl
                targetingType={TARGETING_TYPE.DC}
                targeting={targeting[TARGETING_TYPE.DC]}
                handleTargetingChanged={(t) =>
                  this.handleTargetingChanged(t, TARGETING_TYPE.DC)
                }
              ></TargetingWithInExControl>
            </div>

            <div className={targetingBlockClass}>
              <div className={targetingTitleClass}>Country</div>
              <TargetingWithInExControl
                targetingType={TARGETING_TYPE.COUNTRY}
                targeting={targeting[TARGETING_TYPE.COUNTRY]}
                handleTargetingChanged={(t) =>
                  this.handleTargetingChanged(t, TARGETING_TYPE.COUNTRY)
                }
              ></TargetingWithInExControl>
            </div>

            <div className={targetingBlockClass}>
              <div className={targetingTitleClass}>Browser</div>
              <TargetingWithInExControl
                targetingType={TARGETING_TYPE.BROWSER}
                targeting={targeting[TARGETING_TYPE.BROWSER]}
                handleTargetingChanged={(t) =>
                  this.handleTargetingChanged(t, TARGETING_TYPE.BROWSER)
                }
              ></TargetingWithInExControl>
            </div>

            <div className={targetingBlockClass}>
              <div className={targetingTitleClass}>Inventory Registry</div>
              <TargetingWithInExControl
                targetingType={TARGETING_TYPE.INVENTORY_REGISTRY}
                targeting={targeting[TARGETING_TYPE.INVENTORY_REGISTRY]}
                handleTargetingChanged={(t) =>
                  this.handleTargetingChanged(
                    t,
                    TARGETING_TYPE.INVENTORY_REGISTRY
                  )
                }
              ></TargetingWithInExControl>
            </div>
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 text-lg font-bold">
              Prices in {networkInfo.currency}
            </div>
            <div>
              <PricesControl
                optimization={optimization}
                prices={prices}
                limit={getPricesLimit({ optimization })}
                // limit={this.getPricesLimit()}
                handlePricesChanged={this.handlePricesChanged}
              ></PricesControl>
            </div>
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 text-lg font-bold">
              Detector Prices in {networkInfo.currency}
            </div>
            <div>
              <PricesControl
                optimization={optimization}
                prices={detector_prices}
                limit={getDetectorPricesLimit({ optimization })}
                handlePricesChanged={this.handleDetectorPricesChanged}
              ></PricesControl>
            </div>
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 text-lg font-bold">
              Experiment (X) Prices in {networkInfo.currency}
            </div>
            <div>
              <PricesControl
                optimization={optimization}
                prices={x_prices}
                limit={getXPricesLimit({ optimization })}
                handlePricesChanged={this.handleXPricesChanged}
              ></PricesControl>
            </div>
          </div>

          <div className="border-t rounded mb-4 bg-gray-300 p-2">
            <div className="border-b mt-2 text-lg font-bold">
              Catchall Prices
            </div>
            <div>
              <PricesControl
                optimization={optimization}
                currency={networkInfo.currency}
                prices={catchall_prices}
                limit={getCatchallPricesLimit({ optimization })}
                // limit={this.getCatchallPricesLimit()}
                handlePricesChanged={this.handleCatchallPricesChanged}
              ></PricesControl>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PricesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    // prices: array of number
    // limit: { min, max }
    const { prices, limit } = this.props;
    let pricesString = "";
    if (prices) {
      pricesString = prices.join(",");
    }

    this.state = {
      prices,
      pricesString,

      isInvalid: false,
      errMsg: null,
    };

    this.handlePricesStringChanged = this.handlePricesStringChanged.bind(this);
  }

  componentDidMount() {
    if (this.props.prices) {
      this.isPricesInvalid(this.props.prices);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.optimization !== this.props.optimization) {
      this.isPricesInvalid(this.state.prices);
    }
  }

  handlePricesStringChanged(e) {
    let prices = e.target.value.replace(/\s/g, ""); // remove all whitespace
    prices = _.compact(prices.split(","));
    prices = _.map(prices, (p) => {
      return _.toNumber(p);
    });
    this.setState({ pricesString: e.target.value, prices });
    this.isPricesInvalid(prices);

    this.props.handlePricesChanged(prices);
  }

  isPricesInvalid(prices) {
    const { limit } = this.props;
    const { isInvalid, errMsg } = isPricesInvalid({ prices, limit });
    this.setState({ isInvalid, errMsg });
  }

  render() {
    const { limit } = this.props;
    const { prices, pricesString, isInvalid, errMsg } = this.state;

    // limit.options: [5,10] (num of prices could only be 5 or 10)
    const { min, max, options } = limit;

    const dontNeedPrices = min === 0 && max === 0;

    return (
      <div>
        <div className="mb-4">
          {dontNeedPrices ? (
            <div>dt does not required prices</div>
          ) : (
            <div>
              {min !== undefined && max && (
                <div className="text-xs">
                  Number of prices: Min: {min}, Max: {max}
                </div>
              )}
              {options && (
                <div className="text-xs">
                  Number of prices: {options.join(" or ")}
                </div>
              )}
              <textarea
                className={inputClass}
                rows="1"
                value={pricesString}
                onChange={this.handlePricesStringChanged}
              ></textarea>
              <div className="text-xs text-gray-700">
                {(prices && prices.length) || 0} prices
              </div>

              {isInvalid && (
                <div className="bg-red-100 px-2 font-semibold text-red-700">
                  {errMsg}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const OPTIMIZATION_TYPE = {
  DT: "dt",
  CST_V1: "cstv1",
  CST_V2_P: "cstv2p",
  CST_V2_NP: "cstv2np",
};
class OptimizationControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const { optimization } = this.props;

    this.state = {
      optimization,
    };

    this.handleOptTypeChanged = this.handleOptTypeChanged.bind(this);
  }

  // radioValue
  handleOptTypeChanged(optType) {
    this.setState({ optimization: optType });
    this.props.handleOptimizationChanged(optType);
  }

  render() {
    const { optimization } = this.state;

    return (
      <div>
        <div className="mb-4">
          {/* <div className="block text-gray-800 font-bold">Optimization</div> */}
          <div className="flex items-center gap-4">
            {_.keys(OPTIMIZATION_TYPE).map((key) => {
              const value = OPTIMIZATION_TYPE[key];
              return (
                <label
                  key={key}
                  htmlFor={`radio_opt_type_${key}`}
                  className="cursor-pointer"
                >
                  <input
                    id={`radio_opt_type_${key}`}
                    type="radio"
                    name={`radio_optimization`}
                    value={value}
                    checked={optimization === value}
                    onChange={() => this.handleOptTypeChanged(value)}
                  />{" "}
                  {value}
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Important! snake case!!! camelCase is in customUPR
const TARGETING_TYPE = {
  OS: "os",
  DC: "device_category",
  COUNTRY: "country",
  BROWSER: "browser",
  INVENTORY_REGISTRY: "inventory_registry",
};

const TARGETING_OP = {
  NONE: "NONE",
  INCLUDES: "includes",
  EXCLUDES: "excludes",
};

// type: country, os, device_category, hour
class TargetingWithInExControl extends React.PureComponent {
  constructor(props) {
    super(props);
    // targetingType
    // targeting.includes
    // handleTargetingChanged

    const { targeting } = props;

    let radioValue = TARGETING_OP.NONE;
    let selectedItems = [];
    if (targeting) {
      if (targeting.includes) {
        radioValue = TARGETING_OP.INCLUDES;
        selectedItems = targeting.includes;
      } else {
        radioValue = TARGETING_OP.EXCLUDES;
        selectedItems = targeting.excludes;
      }
    }

    this.state = {
      radioValue,
      selectedItems,
    };

    this.handleChangeRadio = this.handleChangeRadio.bind(this);
    this.handleItemsChanged = this.handleItemsChanged.bind(this);
  }

  handleChangeRadio(value) {
    this.setState({
      radioValue: value,
    });

    if (value === TARGETING_OP.NONE) {
      this.props.handleTargetingChanged(null);
      this.setState({ selectedItems: [] });
    } else {
      const items = this.state.selectedItems;
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [value]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  handleItemsChanged(items) {
    const { radioValue } = this.state;
    // console.log("items changed", items, radioValue);
    if (radioValue !== TARGETING_OP.NONE) {
      if (_.isEmpty(items)) {
        this.props.handleTargetingChanged(null);
      } else {
        let tt = {
          [radioValue]: items,
        };
        this.props.handleTargetingChanged(tt);
      }
    }
  }

  render() {
    // type: country, os, device_category, hour
    const { targetingType } = this.props;
    const { radioValue, selectedItems } = this.state;

    let TargetingSelector = "";
    switch (targetingType) {
      case TARGETING_TYPE.DC: {
        TargetingSelector = (
          <DeviceCategorySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></DeviceCategorySelector>
        );
        break;
      }

      case TARGETING_TYPE.OS: {
        TargetingSelector = (
          <OSSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></OSSelector>
        );
        break;
      }

      case TARGETING_TYPE.COUNTRY: {
        TargetingSelector = (
          <CountrySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></CountrySelector>
        );
        break;
      }

      case TARGETING_TYPE.BROWSER: {
        TargetingSelector = (
          <BrowserSelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></BrowserSelector>
        );
        break;
      }

      case TARGETING_TYPE.INVENTORY_REGISTRY: {
        TargetingSelector = (
          <InventoryRegistrySelector
            selectedItems={selectedItems}
            handleChanged={this.handleItemsChanged}
          ></InventoryRegistrySelector>
        );
        break;
      }

      default: {
        TargetingSelector = "Selector not implemented";
      }
    }

    const isExcludesDisabled =
      targetingType === TARGETING_TYPE.INVENTORY_REGISTRY;

    return (
      <>
        <div className="flex items-center gap-4">
          <label
            htmlFor={`${targetingType}_radio_none`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_none`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.NONE}
              checked={radioValue === TARGETING_OP.NONE}
              onChange={() => this.handleChangeRadio(TARGETING_OP.NONE)}
            />{" "}
            None
          </label>

          <label
            htmlFor={`${targetingType}_radio_include`}
            className="cursor-pointer"
          >
            <input
              id={`${targetingType}_radio_include`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.INCLUDES}
              checked={radioValue === TARGETING_OP.INCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.INCLUDES)}
            />{" "}
            Includes
          </label>

          <label
            htmlFor={`${targetingType}_radio_exclude`}
            className={
              isExcludesDisabled
                ? "cursor-not-allowed text-gray-500"
                : "cursor-pointer"
            }
          >
            <input
              id={`${targetingType}_radio_exclude`}
              type="radio"
              name={`${targetingType}_radio`}
              value={TARGETING_OP.EXCLUDES}
              checked={radioValue === TARGETING_OP.EXCLUDES}
              onChange={() => this.handleChangeRadio(TARGETING_OP.EXCLUDES)}
              disabled={isExcludesDisabled}
            />{" "}
            Excludes
          </label>
        </div>

        {radioValue !== TARGETING_OP.NONE && TargetingSelector}
      </>
    );
  }
}

export default NetworkUPRSegmentationForm;
