import React from "react";
import _ from "lodash";
import AdSizesControl from "./AdSizesControl";
// import AdSizesLayersControl from "./AdSizesLayersControl";
import SetsOfAdSizesControl from "./SetsOfAdSizesControl";
import DeviceCategoriesControl from "./DeviceCategoriesControl";
import ClientRefreshLabelsControl from "./ClientRefreshLabelsControl";
import DemandTypesControl from "./DemandTypesControl";
import BatchOfSegmentsControl from "./BatchOfSegmentsControl";
import ArrayTextAreaControl from "./ArrayTextAreaControl";
import UprPricesControl from "./UprPricesControl";
import CstAlgorithmsControl from "./CstAlgorithmsControl";
import CstScheduleControl from "./CstScheduleControl";
import PriceGroupsControl from "./PriceGroupsControl";
import CurrencyControl from "./CurrencyControl";
import RequestSignalAugmentationControl from "./RequestSignalAugmentationControl";

class APConfigItemForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const { valueType, configValue, defaultValue, valueSchema } = this.props;

    let inputValue = configValue;

    if (_.isUndefined(inputValue)) {
      // give initial default value
      if (valueType === "boolean") {
        inputValue = true;
      } else if (valueType === "integer_enum" || valueType === "string_enum") {
        const options = valueSchema.enum;
        const keys = _.keys(options);
        inputValue = _.first(keys);
      } else if (valueType === "ad_sizes") {
        inputValue = [];
      } else if (valueType === "ad_sizes_layers") {
        inputValue = [];
      } else if (valueType === "sets_of_ad_sizes") {
        inputValue = [];
      } else if (valueType === "upr_prices") {
        inputValue = [];
      } else if (valueType === "device_categories") {
        inputValue = [];
      } else if (valueType === "client_refresh_labels") {
        inputValue = [];
      } else if (valueType === "demand_types") {
        inputValue = [];
      } else if (valueType === "batch_of_segments") {
        inputValue = {};
      } else if (valueType === "array_string") {
        inputValue = [];
      } else if (valueType === "array_integer") {
        inputValue = [];
      } else if (valueType === "cst_predict_schedule") {
        inputValue = {};
      } else if (valueType === "rsa_object") {
        inputValue = defaultValue;
      } else if (valueType === "price_groups") {
        inputValue = [];
      } else {
        inputValue = "";
      }
    }

    props.handleNewValueChanged(inputValue);

    this.state = {
      inputValue,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
  }

  handleInputChanged(e) {
    this.setState({
      inputValue: e.target.value,
    });

    this.props.handleNewValueChanged(e.target.value);
  }

  render() {
    const { inputValue } = this.state;
    const {
      unitId,
      networkId,
      valueType,
      configValue,
      valueSchema,
      handleNewValueChanged,
      handleHasError,
    } = this.props;
    const basicInputClass =
      "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

    switch (valueType) {
      case "integer": {
        const { minimum, maximum } = valueSchema;
        return (
          <div>
            <input
              type="number"
              step="1"
              min={minimum}
              max={maximum}
              value={inputValue}
              onChange={this.handleInputChanged}
              className={basicInputClass}
              required
            ></input>
          </div>
        );
      }

      case "float": {
        const { minimum, maximum } = valueSchema;
        return (
          <div>
            <input
              type="number"
              step="0.01"
              min={minimum}
              max={maximum}
              value={inputValue}
              onChange={this.handleInputChanged}
              className={basicInputClass}
              required
            ></input>
          </div>
        );
      }

      case "boolean": {
        let value = inputValue;

        if (typeof inputValue === "boolean") {
          value = inputValue ? "true" : "false";
        }

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        );
      }

      case "integer_enum": {
        let value = inputValue;

        const options = valueSchema.enum;
        const keys = _.keys(options);

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              {keys.map((key) => {
                return (
                  <option key={key} value={key}>
                    {key == options[key] ? `${key}` : `${key}: ${options[key]}`}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }

      case "string_enum": {
        let value = inputValue;

        const options = valueSchema.enum;
        const keys = _.keys(options);

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              {keys.map((key) => {
                return (
                  <option key={key} value={key}>
                    {key}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }

      case "device_categories": {
        return (
          <div>
            <DeviceCategoriesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></DeviceCategoriesControl>
          </div>
        );
      }

      case "client_refresh_labels": {
        return (
          <div>
            <ClientRefreshLabelsControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></ClientRefreshLabelsControl>
          </div>
        );
      }

      case "demand_types": {
        return (
          <div>
            <DemandTypesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></DemandTypesControl>
          </div>
        );
      }

      case "ad_sizes": {
        return (
          <div className="ml-4">
            <AdSizesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
            ></AdSizesControl>
          </div>
        );
      }

      case "ad_sizes_layers": {
        return (
          <div className="ml-4">
            {/* <AdSizesLayersControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              allowFluidSize={true}
            ></AdSizesLayersControl> */}

            <AdSizesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              allowFluidSize={true}
            ></AdSizesControl>
          </div>
        );
      }

      case "sets_of_ad_sizes": {
        return (
          <SetsOfAdSizesControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
          ></SetsOfAdSizesControl>
        );
      }

      case "batch_of_segments": {
        return (
          <BatchOfSegmentsControl
            networkId={networkId}
            unitId={unitId}
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
          ></BatchOfSegmentsControl>
        );
      }

      case "array_string":
      case "array_integer":
      case "array_float": {
        return (
          <ArrayTextAreaControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
            handleHasError={handleHasError}
          ></ArrayTextAreaControl>
        );
      }

      case "upr_prices": {
        return (
          <div className="ml-4">
            <UprPricesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></UprPricesControl>
          </div>
        );
      }

      case "price_groups": {
        return (
          <div className="ml-4">
            <PriceGroupsControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></PriceGroupsControl>
          </div>
        );
      }

      case "cst_algorithms": {
        return (
          <div className="ml-4">
            <CstAlgorithmsControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></CstAlgorithmsControl>
          </div>
        );
      }

      case "cst_predict_schedule": {
        return (
          <CstScheduleControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
            handleHasError={handleHasError}
          ></CstScheduleControl>
        );
      }

      case "currency": {
        return (
          <CurrencyControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
            handleHasError={handleHasError}
          ></CurrencyControl>
        );
      }

      case "rsa_object": {
        return (
          <RequestSignalAugmentationControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
            handleHasError={handleHasError}
          ></RequestSignalAugmentationControl>
        );
      }

      default: {
      }
    }

    return (
      <div>
        {valueType}: {configValue}
      </div>
    );
  }
}

export default APConfigItemForm;
