import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import UnitsSelector from "../../../common/UnitsSelector";
import CustomSetTargetingControlV2 from "../../../control-center/auto-pilot/config/CustomSetTargetingControlV2";
import PricingSectionControl from "./PricingSectionControl";
import TargetingWithInExControl from "./TargetingWithInExControl";
import BrandedPriceSettingsSection from "./BrandedPriceSettingsSection";
import VirtualPlacementSelector from "components/common/VirtualPlacementSelector";
import GAMInventoryTypeSelector from "./GAMInventoryTypeSelector";
import { buttonActionClass } from "helpers/StyleClass";
import { NetworkAPI } from "apis";
import { transformGamUprToUprConfig } from "../gam-upr/GamUprHelper";
import LoadingUI from "components/common/LoadingUI";

const inputClass =
  "bg-white flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:border-gray-500";
const inputClassDisabled =
  "bg-gray-300 flex w-full border border-gray-400 rounded py-1 px-3 appearance-none leading-normal";

const targetingBlockClass = "border p-2 my-2 bg-white";
const targetingTitleClass = "block text-gray-800 font-bold mb-1"; //"font-semibold";

// Important! not snake case!!!!!!! snake case is in apconfig
const TARGETING_TYPE = {
  OS: "os",
  DC: "deviceCategory",
  COUNTRY: "country",
  BROWSER: "browser",
  CUSTOMS: "customs",
  INVENTORY_TYPES: "inventoryTypes",
  INVENTORY_URL: "inventoryUrl",
  VIDEO_POSITIONS: "videoPositions",
  INVENTORY_FORMAT: "inventoryFormat",
};

class NetworkCustomUPRForm extends React.Component {
  constructor(props) {
    super(props);

    let { uprSetting, importId } = props;
    const {
      id = "",
      name = "",
      priceSection = {},
      targetingSection = {},
    } = uprSetting;

    let placementIdsString = _.get(
      targetingSection,
      ["inventory", "placementIds"],
      []
    ).join(",");

    this.state = {
      id,
      name,
      priceSection,
      targetingSection,
      placementIdsString,

      importUprId: importId || "",
      importErrMsg: null,
      isLoading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTargetingChanged = this.handleTargetingChanged.bind(this);
    this.handleInventoryUnitsChanged =
      this.handleInventoryUnitsChanged.bind(this);
    this.handleInventoryExcludeUnitsChanged =
      this.handleInventoryExcludeUnitsChanged.bind(this);
    this.handlePlacementIdsChanged = this.handlePlacementIdsChanged.bind(this);
    this.handleVirtualPlacementIdsChanged =
      this.handleVirtualPlacementIdsChanged.bind(this);
    this.handlePriceTypeChanged = this.handlePriceTypeChanged.bind(this);
    this.handlePriceChanged = this.handlePriceChanged.bind(this);
    this.handleBrandPriceSettingsChanged =
      this.handleBrandPriceSettingsChanged.bind(this);

    this.handleImportUprIdChanged = this.handleImportUprIdChanged.bind(this);
    this.handleImportUpr = this.handleImportUpr.bind(this);
  }

  componentDidMount() {
    if (this.props.importId) {
      this.handleImportUpr(this.props.importId);
    }
  }

  handleImportUprIdChanged(e) {
    this.setState({ importUprId: e.target.value, importErrMsg: null });
  }

  async handleImportUpr(importUprId) {
    try {
      this.setState({
        isLoading: true,
      });
      const { networkId } = this.props;
      const r = await NetworkAPI.getGamUpr({ networkId, uprId: importUprId });
      if (!r.externalId) {
        throw new Error("No UPR found");
      }

      const importedUpr = _transformToUprSetting({ gamUpr: r });
      const uprSetting = {
        enabled: true,
        priceSection: importedUpr.priceSection,
        targetingSection: importedUpr.targetingSection,
        name: `${importedUpr.uprName} (imported at ${moment().format(
          "YYYY-MM-DD HH:mm:ss"
        )})`,
        id: _.parseInt(importedUpr.externalId) + 10000000000,
      };

      function _transformToUprSetting({ gamUpr }) {
        // priceSection
        // targetingSection
        /// extras that custom upr doesnt support yet (eg. inventoryUrl)

        const transformedUpr = transformGamUprToUprConfig({ gamUpr });
        console.log("transformedUpr", transformedUpr);
        console.log("gamUpr", gamUpr);

        return transformedUpr; //gamUpr;
      }

      const {
        id = "",
        name = "",
        priceSection = {},
        targetingSection = {},
      } = uprSetting;

      let placementIdsString = _.get(
        targetingSection,
        ["inventory", "placementIds"],
        []
      ).join(",");

      this.setState({
        uprSetting,
        id,
        name,
        priceSection,
        targetingSection,
        placementIdsString,
        importErrMsg: null,
      });
      this.props.handleCurrentRuleChanged(uprSetting);
    } catch (err) {
      console.log(err, err.toString());
      this.setState({ importErrMsg: err.toString() });
    }
    this.setState({ isLoading: false });
  }

  handleChange(e, key) {
    const value = e.target.value;
    this.setState({
      [key]: value,
    });

    let newRule = this.props.uprSetting;
    newRule[key] = value;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handlePlacementIdsChanged(e) {
    const placementIdsString = _.trim(e.target.value);
    let placementIds = placementIdsString.split(",");
    placementIds = _.uniq(_.compact(placementIds));

    let { targetingSection } = this.state;
    _.set(targetingSection, ["inventory", "placementIds"], placementIds);
    this.setState({
      targetingSection,
      placementIdsString,
    });

    let newRule = this.props.uprSetting;
    newRule.targetingSection = targetingSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleTargetingChanged(targeting, key) {
    // console.log(targeting, key);
    let { targetingSection } = this.state;
    targetingSection[key] = targeting;
    if (!targeting) {
      targetingSection = _.omit(targetingSection, key);
    }

    this.setState({
      targetingSection,
    });

    let newRule = this.props.uprSetting;
    newRule.targetingSection = targetingSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleInventoryUnitsChanged(unitIds) {
    let { targetingSection } = this.state;
    _.set(targetingSection, ["inventory", "unitIds"], unitIds);
    this.setState({ targetingSection });

    let newRule = this.props.uprSetting;
    newRule.targetingSection = targetingSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleInventoryExcludeUnitsChanged(unitIds) {
    let { targetingSection } = this.state;
    _.set(targetingSection, ["inventory", "excludedUnitIds"], unitIds);
    this.setState({ targetingSection });

    let newRule = this.props.uprSetting;
    newRule.targetingSection = targetingSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleVirtualPlacementIdsChanged(virtualPlacementIds) {
    let { targetingSection } = this.state;
    _.set(
      targetingSection,
      ["inventory", "virtualPlacementIds"],
      virtualPlacementIds
    );
    this.setState({ targetingSection });

    let newRule = this.props.uprSetting;
    newRule.targetingSection = targetingSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handlePriceTypeChanged(priceType) {
    let { priceSection } = this.state;
    priceSection.priceType = priceType;

    // LGO does not need price
    if (priceType === 2) {
      priceSection = _.omit(priceSection, "price");
    }

    // no price setting
    if (priceType === -1) {
      priceSection = {
        priceType: -1,
        price: -1,
      };
    }

    this.setState({
      priceSection,
    });

    let newRule = this.props.uprSetting;
    newRule.priceSection = priceSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handlePriceChanged(price) {
    let { priceSection } = this.state;
    priceSection.price = price;
    this.setState({
      priceSection,
    });

    let newRule = this.props.uprSetting;
    newRule.priceSection = priceSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  handleBrandPriceSettingsChanged(brandPriceSettings) {
    let { priceSection } = this.state;

    let newPriceSection = { ...priceSection };
    newPriceSection.brandedPriceSettings = brandPriceSettings;
    if (_.isEmpty(newPriceSection.brandedPriceSettings)) {
      newPriceSection = _.omit(newPriceSection, "brandedPriceSettings");
    }

    this.setState({
      priceSection: newPriceSection,
    });

    let newRule = this.props.uprSetting;
    newRule.priceSection = newPriceSection;
    this.props.handleCurrentRuleChanged(newRule);
  }

  render() {
    const { networkId, networkInfo, modalType } = this.props;
    const {
      id,
      name,
      priceSection,
      targetingSection,
      placementIdsString,
      importUprId,
      importErrMsg,
      isLoading,
    } = this.state;

    return (
      <div>
        <div className="rounded mb-4 bg-white px-8 pt-6 pb-8">
          {modalType === "ADD" && (
            <div className="rounded mb-4 bg-gray-200 p-4">
              <div className="flex items-center gap-2">
                <div>
                  <label
                    className="block font-bold text-gray-800"
                    htmlFor="importRuleId"
                  >
                    UPR External ID
                  </label>
                  <input
                    className={inputClass}
                    id="importRuleId"
                    type="number"
                    value={importUprId}
                    onChange={(e) => this.handleImportUprIdChanged(e)}
                  />
                </div>
              </div>
              <button
                className={buttonActionClass}
                onClick={() => this.handleImportUpr(importUprId)}
              >
                Import
              </button>
              <div className="bg-red-200 px-4 font-semibold text-red-800">
                {importErrMsg}
              </div>
            </div>
          )}

          {isLoading ? (
            <LoadingUI></LoadingUI>
          ) : (
            <>
              <div className="mb-4">
                <label
                  className="block font-bold text-gray-800"
                  htmlFor="ruleId"
                >
                  ID
                </label>
                <input
                  className={
                    modalType === "ADD" ? inputClass : inputClassDisabled
                  }
                  id="ruleId"
                  type="number"
                  value={id}
                  onChange={(e) => this.handleChange(e, "id")}
                  disabled={modalType !== "ADD"}
                />
              </div>

              <div className="mb-4">
                <label className="block font-bold text-gray-800" htmlFor="name">
                  Name
                </label>
                <input
                  className={inputClass}
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => this.handleChange(e, "name")}
                />
              </div>

              <div className="border-t rounded mb-4 bg-gray-300 px-4 py-2">
                <div className="border-b mt-2 mb-2 text-lg font-bold">
                  Pricing
                </div>
                <div>
                  <PricingSectionControl
                    currency={networkInfo.currency}
                    priceSection={priceSection}
                    handlePriceChanged={this.handlePriceChanged}
                    handlePriceTypeChanged={this.handlePriceTypeChanged}
                  ></PricingSectionControl>
                </div>
              </div>

              <div className="border-t rounded mb-4 bg-gray-300 px-4 py-2">
                <div className="border-b mt-2 mb-2 text-lg font-bold">
                  Brand Price Settings
                </div>
                <div>
                  <BrandedPriceSettingsSection
                    networkId={networkInfo.networkId}
                    currency={networkInfo.currency}
                    brandedPriceSettings={priceSection.brandedPriceSettings}
                    handleBrandPriceSettingsChanged={
                      this.handleBrandPriceSettingsChanged
                    }
                    handleIsEditing={this.props.handleIsEditing}
                  ></BrandedPriceSettingsSection>
                </div>
              </div>

              <div className="border-t rounded mb-4 bg-gray-300 px-4 py-2">
                <div className="border-b mt-2 text-lg font-bold">Targeting</div>
                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Inventory</div>
                  <div>
                    Unit IDs:{" "}
                    <span className="text-sm text-gray-700">
                      (you can also paste a list of "," separated <i>unitIds</i>{" "}
                      or <i>extGamUnitIds</i>)
                    </span>
                  </div>
                  <UnitsSelector
                    networkId={networkId}
                    handleChanged={this.handleInventoryUnitsChanged}
                    isCreatable={true}
                    selectedItems={
                      targetingSection.inventory
                        ? targetingSection.inventory.unitIds
                        : []
                    }
                  ></UnitsSelector>
                  <div className="text-xs text-gray-600">
                    {(targetingSection.inventory &&
                      targetingSection.inventory.unitIds &&
                      targetingSection.inventory.unitIds.length) ||
                      0}{" "}
                    unitIds
                  </div>

                  <div className="mt-2">
                    Exclude Unit IDs:{" "}
                    <span className="text-sm text-gray-700">
                      (you can also paste a list of "," separated <i>unitIds</i>{" "}
                      or <i>extGamUnitIds</i>)
                    </span>
                  </div>
                  <UnitsSelector
                    networkId={networkId}
                    handleChanged={this.handleInventoryExcludeUnitsChanged}
                    isCreatable={true}
                    selectedItems={
                      targetingSection.inventory
                        ? targetingSection.inventory.excludedUnitIds
                        : []
                    }
                  ></UnitsSelector>
                  <div className="text-xs text-gray-600">
                    Excluding{" "}
                    {(targetingSection.inventory &&
                      targetingSection.inventory.excludedUnitIds &&
                      targetingSection.inventory.excludedUnitIds.length) ||
                      0}{" "}
                    unitIds
                  </div>

                  <div className="mt-4">Placement IDs: ("," separated)</div>
                  <textarea
                    className={inputClass}
                    id="placementIds"
                    rows="1"
                    value={placementIdsString}
                    onChange={this.handlePlacementIdsChanged}
                  ></textarea>
                  <div className="text-xs text-gray-600">
                    {(targetingSection.inventory &&
                      targetingSection.inventory.placementIds &&
                      targetingSection.inventory.placementIds.length) ||
                      0}{" "}
                    placements
                  </div>

                  <div className="mt-2">
                    Virtual Placement IDs:{" "}
                    <span className="text-sm text-gray-700">
                      (you can also paste a list of "," separated{" "}
                      <i>virtualPlacementIds</i>)
                    </span>
                  </div>
                  <VirtualPlacementSelector
                    networkId={networkId}
                    handleChanged={this.handleVirtualPlacementIdsChanged}
                    selectedItems={
                      targetingSection.inventory
                        ? targetingSection.inventory.virtualPlacementIds
                        : []
                    }
                  ></VirtualPlacementSelector>
                  <div className="text-xs text-gray-600">
                    {(targetingSection.inventory &&
                      targetingSection.inventory.virtualPlacementIds &&
                      targetingSection.inventory.virtualPlacementIds.length) ||
                      0}{" "}
                    virtualPlacementIds
                  </div>
                </div>

                {/* <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>GAM Inventory Types</div>
                  <GAMInventoryTypeSelector
                    handleChanged={(value) => {
                      return this.handleTargetingChanged(
                        value,
                        TARGETING_TYPE.INVENTORY_TYPES
                      );
                    }}
                    selectedItems={
                      targetingSection.inventory
                        ? targetingSection.inventoryTypes
                        : []
                    }
                  ></GAMInventoryTypeSelector>
                </div> */}

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>OS</div>
                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.OS}
                    targeting={targetingSection[TARGETING_TYPE.OS]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(t, TARGETING_TYPE.OS)
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Device Category</div>

                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.DC}
                    targeting={targetingSection[TARGETING_TYPE.DC]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(t, TARGETING_TYPE.DC)
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Country</div>
                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.COUNTRY}
                    targeting={targetingSection[TARGETING_TYPE.COUNTRY]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(t, TARGETING_TYPE.COUNTRY)
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Browser</div>
                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.BROWSER}
                    targeting={targetingSection[TARGETING_TYPE.BROWSER]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(t, TARGETING_TYPE.BROWSER)
                    }
                  ></TargetingWithInExControl>
                </div>

                {/* <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Inventory URL</div>
                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.INVENTORY_URL}
                    targeting={targetingSection[TARGETING_TYPE.INVENTORY_URL]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(
                        t,
                        TARGETING_TYPE.INVENTORY_URL
                      )
                    }
                  ></TargetingWithInExControl>
                </div> */}

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Inventory Types</div>
                  <TargetingWithInExControl
                    onlyIncludes={true}
                    targetingType={TARGETING_TYPE.INVENTORY_TYPES}
                    targeting={targetingSection[TARGETING_TYPE.INVENTORY_TYPES]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(
                        t,
                        TARGETING_TYPE.INVENTORY_TYPES
                      )
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Video Positions</div>
                  <TargetingWithInExControl
                    onlyIncludes={true}
                    targetingType={TARGETING_TYPE.VIDEO_POSITIONS}
                    targeting={targetingSection[TARGETING_TYPE.VIDEO_POSITIONS]}
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(
                        t,
                        TARGETING_TYPE.VIDEO_POSITIONS
                      )
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>Inventory Format</div>
                  <TargetingWithInExControl
                    targetingType={TARGETING_TYPE.INVENTORY_FORMAT}
                    targeting={
                      targetingSection[TARGETING_TYPE.INVENTORY_FORMAT]
                    }
                    handleTargetingChanged={(t) =>
                      this.handleTargetingChanged(
                        t,
                        TARGETING_TYPE.INVENTORY_FORMAT
                      )
                    }
                  ></TargetingWithInExControl>
                </div>

                <div className={targetingBlockClass}>
                  <div className={targetingTitleClass}>
                    Customs (
                    {targetingSection[TARGETING_TYPE.CUSTOMS]
                      ? targetingSection[TARGETING_TYPE.CUSTOMS].length
                      : 0}
                    )
                  </div>
                  <div className="bg-gray-200 p-2">
                    <CustomSetTargetingControlV2
                      networkId={networkId}
                      handleIsEditing={this.props.handleIsEditing}
                      targeting={targetingSection[TARGETING_TYPE.CUSTOMS]}
                      handleValueChanged={(t) =>
                        this.handleTargetingChanged(t, TARGETING_TYPE.CUSTOMS)
                      }
                    ></CustomSetTargetingControlV2>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default NetworkCustomUPRForm;
