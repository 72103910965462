import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import {
  GROUP_TYPE_INDEX,
  getPerformanceSectionData,
} from "./AnatomyCstReportHelper";
import HighchartWrapper from "components/common/HighchartWrapper";
import {
  getGroupTypeColor,
  getGroupTypeSeriesOptions,
  tooltipFormatter,
} from "./AnatomyHighchartHelper";
const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

const titleClass = "block text-2xl text-gray-800 font-bold mb-1 mt-2";

class AnatomyCstPerformanceSection extends React.Component {
  constructor(props) {
    super(props);

    this.layerAllRef = React.createRef();
    this.layer1Ref = React.createRef();
    this.layer2Ref = React.createRef();
    // Important! remember to add ref in setExtremes fn below and add redrawCharts

    this.state = {
      options_cost: null,
      options_rev: null,
      // layerall
      options_layerall_rrpm: null,
      options_layerall_rrpm_per: null,
      options_layerall_rrpm_net: null,
      options_layerall_str: null,
      options_layerall_ecpm: null,
      options_layerall_ecpm_b: null,
      options_layerall_unfilled: null,
      options_layerall_str_guarenteed: null,
      options_layerall_str_house: null,
      // layer1
      options_layer1_rrpm: null,
      options_layer1_str: null,
      options_layer1_str_catchall: null,
      options_layer1_str_guarenteed: null,
      options_layer1_str_house: null,
      options_layer1_ecpm: null,
      options_layer1_unfilled: null,
      // layer2
      options_layer2_rrpm: null,
      options_layer2_str: null,
      options_layer2_ecpm: null,
      options_layer2_req: null,
      options_layer_loss: null,
      options_layer2_unfilled: null,
      options_layer2_str_guarenteed: null,
      options_layer2_str_house: null,

      hasLayer2: true,
    };

    this.clearData = this.clearData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.redrawCharts = this.redrawCharts.bind(this);
    this.setExtremes = this.setExtremes.bind(this);

    this.handlePointClick = this.handlePointClick.bind(this);
    this.handleOpenCompareChart = this.handleOpenCompareChart.bind(this);
    this.handleOpenOVDChart = this.handleOpenOVDChart.bind(this);
    this.handleOpenGodviewChart = this.handleOpenGodviewChart.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.uprSegmentId, this.props.uprSegmentId);
    if (prevProps.uprSegmentId !== this.props.uprSegmentId) {
      this.refreshData();
    }

    // console.log(prevProps.dateRangeNum, this.props.dateRangeNum);
    if (prevProps.dateRangeNum !== this.props.dateRangeNum) {
      this.refreshData();
    }

    // console.log(prevProps.aggregation, this.props.aggregation);
    if (prevProps.aggregation !== this.props.aggregation) {
      this.refreshData();
    }

    // console.log(prevProps.demandTypes, this.props.demandTypes);
    if (prevProps.demandTypes !== this.props.demandTypes) {
      this.refreshData();
    }

    // console.log(prevProps.includeToday, this.props.includeToday);
    if (prevProps.includeToday !== this.props.includeToday) {
      this.refreshData();
    }

    // console.log(prevProps.isIncludeGhostImp, this.props.isIncludeGhostImp);
    if (prevProps.isIncludeGhostImp !== this.props.isIncludeGhostImp) {
      this.refreshData();
    }

    // console.log(prevProps.selectedGroupTypes, this.props.selectedGroupTypes);
    if (prevProps.selectedGroupTypes !== this.props.selectedGroupTypes) {
      this.redrawCharts();
    }

    // console.log(prevProps.timezoneType, this.props.timezoneType);
    if (prevProps.timezoneType !== this.props.timezoneType) {
      this.redrawChartTimezone();
    }

    // set extremes
    if (prevProps.st !== this.props.st || prevProps.et !== this.props.et) {
      this.setExtremes({ st: this.props.st, et: this.props.et });
    }
  }

  setExtremes({ st, et }) {
    // The better zoom! Update min max on xAxis, instead of setExtremes
    // when min, max is null, resets xAxis

    if (this.layer2Ref && this.layer2Ref.current) {
      const layer2Refs = [
        "rrpmRef",
        "strRef",
        "ecpmRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",

        "layerLossRef",
        "reqRef",
      ];

      for (let ref of layer2Refs) {
        if (!this.layer2Ref.current[ref]) return;
        if (!this.layer2Ref.current[ref].current) return;

        const chart = this.layer2Ref.current[ref].current.ref.current.chart;
        chart.xAxis[0].update({ min: st, max: et });

        // hide/show reset zoom button
        if (!st && !et) {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.hide();
          }
        } else {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.show();
          } else {
            chart.showResetZoom();
          }
        }
      }
    }

    if (this.layer1Ref && this.layer1Ref.current) {
      const layer1Refs = [
        "rrpmRef",
        "strRef",
        "ecpmRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",
        "catchallStrRef",
      ];

      for (let ref of layer1Refs) {
        if (!this.layer1Ref.current[ref]) return;
        if (!this.layer1Ref.current[ref].current) return;

        const chart = this.layer1Ref.current[ref].current.ref.current.chart;
        chart.xAxis[0].update({ min: st, max: et });

        // hide/show reset zoom button
        if (!st && !et) {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.hide();
          }
        } else {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.show();
          } else {
            chart.showResetZoom();
          }
        }
      }
    }

    if (this.layerAllRef && this.layerAllRef.current) {
      const layerAllRefs = [
        "rrpmRef",
        "ecpmRef",
        "strRef",
        "rrpmDistRef",
        "costRef",
        "ecpmDistRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",
      ];

      for (let ref of layerAllRefs) {
        if (!this.layerAllRef.current[ref]) return;
        if (!this.layerAllRef.current[ref].current) return;

        const chart = this.layerAllRef.current[ref].current.ref.current.chart;
        chart.xAxis[0].update({ min: st, max: et });

        // hide/show reset zoom button
        if (!st && !et) {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.hide();
          }
        } else {
          if (chart.resetZoomButton) {
            chart.resetZoomButton.show();
          } else {
            chart.showResetZoom();
          }
        }
      }
    }
  }

  refreshData() {
    const cb = this.updateData;
    this.clearData(cb);
  }

  handlePointClick({ point, data }) {
    this.props.setDetectorProps({ point, data });
  }

  handleOpenCompareChart() {
    this.props.setDemandTypeCompareProps();
  }

  handleOpenOVDChart() {
    this.props.setOVDProps();
  }

  handleOpenGodviewChart() {
    this.props.setGodviewProps();
  }

  clearData(cb) {
    // console.log("clear data");
    this.setState(
      {
        options_cost: null,
        options_rev: null,
        // layerall
        options_layerall_rrpm: null,
        options_layerall_rrpm_per: null,
        options_layerall_rrpm_net: null,
        options_layerall_str: null,
        options_layerall_ecpm: null,
        options_layerall_ecpm_b: null,
        options_layerall_unfilled: null,
        options_layerall_str_guarenteed: null,
        options_layerall_str_house: null,
        // layer1
        options_layer1_rrpm: null,
        options_layer1_str: null,
        options_layer1_str_catchall: null,
        options_layer1_str_guarenteed: null,
        options_layer1_ecpm: null,
        options_layer1_unfilled: null,
        // layer2
        options_layer2_rrpm: null,
        options_layer2_str: null,
        options_layer2_ecpm: null,
        options_layer2_req: null,
        options_layer_loss: null,
        options_layer2_unfilled: null,
        options_layer2_str_guarenteed: null,
      },
      cb
    );
  }

  updateData() {
    const { data, uprSegmentId, demandTypes, timezone, timezoneType } =
      this.props;

    // const segmentData = getSegmentReports(data, uprSegmentId);

    let { data: pData } = getPerformanceSectionData({
      reports: data,
    });

    const hasLayer2 = _.some(data.performance_report, (r) => {
      return r.layer2_req > 0;
    });

    let tz = timezone;
    if (timezoneType === "TAIPEI") {
      tz = "Asia/Taipei";
    }

    const setExtremeFn = this.props.handleSetExtremes;
    const pointClickFn = (point) => this.handlePointClick({ point, data });
    const chartParams = {
      reportsByGroupType: pData,
      // layerNum,
      // metric,
      timezone: tz,
      setExtremeFn,
      pointClickFn,
    };

    this.setState(
      {
        hasLayer2,
        options_cost: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "cost",
        }),
        options_rev: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "rev",
        }),
        // layerall
        options_layerall_rrpm: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "rrpm",
        }),
        options_layerall_rrpm_per: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "rrpm_per",
        }),
        options_layerall_rrpm_net: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "rrpm_net",
        }),
        options_layerall_str: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "str",
        }),
        options_layerall_ecpm: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "ecpm",
        }),
        options_layerall_ecpm_b: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "ecpm_b",
        }),
        options_layerall_unfilled: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "unfilled_rate",
        }),
        options_layerall_str_guarenteed: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "str_guarenteed",
        }),
        options_layerall_str_house: _createChartOptions({
          ...chartParams,
          layerNum: "all",
          metric: "str_house",
        }),
        // layer1
        options_layer1_rrpm: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "rrpm",
        }),
        options_layer1_str: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "str",
        }),
        options_layer1_str_catchall: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "str_catchall",
        }),
        options_layer1_str_guarenteed: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "str_guarenteed",
        }),
        options_layer1_str_house: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "str_house",
        }),
        options_layer1_ecpm: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "ecpm",
        }),
        options_layer1_unfilled: _createChartOptions({
          ...chartParams,
          layerNum: 1,
          metric: "unfilled_rate",
        }),
        // layer2
        options_layer2_rrpm: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "rrpm",
        }),
        options_layer2_str: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "str",
        }),
        options_layer2_ecpm: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "ecpm",
        }),
        options_layer2_req: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "req",
        }),
        options_layer2_unfilled: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "unfilled_rate",
        }),
        options_layer2_str_guarenteed: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "str_guarenteed",
        }),
        options_layer2_str_house: _createChartOptions({
          ...chartParams,
          layerNum: 2,
          metric: "str_house",
        }),
        options_layer_loss: _createChartOptions({
          ...chartParams,
          layerNum: null,
          metric: "layer_loss",
        }),
      },
      () => {
        return this.redrawCharts();
      }
    );
  }

  redrawChartTimezone() {
    // console.log("redraw chart timezone");
    const { timezone, timezoneType } = this.props;

    let tz = timezone;
    if (timezoneType === "TAIPEI") {
      tz = "Asia/Taipei";
    }
    const timezoneOffset = -moment.tz(tz).utcOffset();

    const options_cost = {
      ...this.state.options_cost,
      time: { timezoneOffset },
    };
    const options_rev = {
      ...this.state.options_rev,
      time: { timezoneOffset },
    };
    const options_layerall_rrpm = {
      ...this.state.options_layerall_rrpm,
      time: { timezoneOffset },
    };
    const options_layerall_rrpm_per = {
      ...this.state.options_layerall_rrpm_per,
      time: { timezoneOffset },
    };
    const options_layerall_rrpm_net = {
      ...this.state.options_layerall_rrpm_net,
      time: { timezoneOffset },
    };
    const options_layerall_str = {
      ...this.state.options_layerall_str,
      time: { timezoneOffset },
    };
    const options_layerall_ecpm = {
      ...this.state.options_layerall_ecpm,
      time: { timezoneOffset },
    };
    const options_layerall_ecpm_b = {
      ...this.state.options_layerall_ecpm_b,
      time: { timezoneOffset },
    };
    const options_layer1_rrpm = {
      ...this.state.options_layer1_rrpm,
      time: { timezoneOffset },
    };
    const options_layer1_str = {
      ...this.state.options_layer1_str,
      time: { timezoneOffset },
    };
    const options_layer1_str_catchall = {
      ...this.state.options_layer1_str_catchall,
      time: { timezoneOffset },
    };
    const options_layer1_str_guarenteed = {
      ...this.state.options_layer1_str_guarenteed,
      time: { timezoneOffset },
    };
    const options_layer1_str_house = {
      ...this.state.options_layer1_str_house,
      time: { timezoneOffset },
    };
    const options_layer2_str_house = {
      ...this.state.options_layer2_str_house,
      time: { timezoneOffset },
    };
    const options_layerall_str_house = {
      ...this.state.options_layerall_str_house,
      time: { timezoneOffset },
    };
    const options_layer1_ecpm = {
      ...this.state.options_layer1_ecpm,
      time: { timezoneOffset },
    };
    const options_layer2_rrpm = {
      ...this.state.options_layer2_rrpm,
      time: { timezoneOffset },
    };
    const options_layer2_str = {
      ...this.state.options_layer2_str,
      time: { timezoneOffset },
    };
    const options_layer2_ecpm = {
      ...this.state.options_layer2_ecpm,
      time: { timezoneOffset },
    };
    const options_layer2_req = {
      ...this.state.options_layer2_req,
      time: { timezoneOffset },
    };
    const options_layer_loss = {
      ...this.state.options_layer_loss,
      time: { timezoneOffset },
    };
    this.setState({
      options_cost,
      options_rev,
      // layerall
      options_layerall_rrpm,
      options_layerall_rrpm_per,
      options_layerall_rrpm_net,
      options_layerall_str,
      options_layerall_ecpm,
      options_layerall_ecpm_b,
      options_layerall_str_house,
      // layer1
      options_layer1_rrpm,
      options_layer1_str,
      options_layer1_str_catchall,
      options_layer1_str_guarenteed,
      options_layer1_str_house,
      options_layer1_ecpm,
      // layer2
      options_layer2_rrpm,
      options_layer2_str,
      options_layer2_ecpm,
      options_layer2_req,
      options_layer_loss,
      options_layer2_str_house,
    });
  }

  redrawCharts() {
    const { selectedGroupTypes } = this.props;

    setTimeout(() => {
      let layerAllCharts = [];
      const layerAllChartRefs = [
        "rrpmRef",
        "ecpmRef",
        "strRef",
        "rrpmDistRef",
        "costRef",
        "ecpmDistRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",
      ];
      if (this.layerAllRef && this.layerAllRef.current) {
        _.forEach(layerAllChartRefs, (refName) => {
          if (!this.layerAllRef.current[refName].current) return;
          layerAllCharts.push(
            this.layerAllRef.current[refName].current.ref.current.chart
          );
        });
      }

      let layer2Charts = [];
      const layer2ChartRefs = [
        "rrpmRef",
        "ecpmRef",
        "strRef",
        "reqRef",
        "layerLossRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",
      ];
      if (this.layer2Ref && this.layer2Ref.current) {
        _.forEach(layer2ChartRefs, (refName) => {
          if (!this.layer2Ref.current[refName].current) return;
          layer2Charts.push(
            this.layer2Ref.current[refName].current.ref.current.chart
          );
        });
      }

      let layer1Charts = [];
      const layer1ChartRefs = [
        "rrpmRef",
        "ecpmRef",
        "strRef",
        "unfilledRef",
        "guarenteedStrRef",
        "houseStrRef",
        "catchallStrRef",
      ];
      if (this.layer1Ref && this.layer1Ref.current) {
        _.forEach(layer1ChartRefs, (refName) => {
          if (!this.layer1Ref.current[refName].current) return;
          layer1Charts.push(
            this.layer1Ref.current[refName].current.ref.current.chart
          );
        });
      }

      const trendCharts = [...layerAllCharts, ...layer1Charts, ...layer2Charts];

      trendCharts.forEach((chart) => {
        const series = chart.series;

        for (let s of series) {
          if (_.indexOf(selectedGroupTypes, s.name) !== -1) {
            s.show();
          } else {
            s.hide();
          }
        }
      });
    }, 100);
  }

  render() {
    const {
      options_layer1_rrpm,
      options_layer1_str,
      options_layer1_str_catchall,
      options_layer1_str_guarenteed,
      options_layer1_str_house,
      options_layer1_ecpm,
      options_layer1_unfilled,
      options_layer2_rrpm,
      options_layer2_str,
      options_layer2_ecpm,
      options_layer2_req,
      options_layer2_unfilled,
      options_layer2_str_guarenteed,
      options_layer2_str_house,
      options_layer_loss,
      options_layerall_rrpm,
      options_layerall_rrpm_per,
      options_layerall_rrpm_net,
      options_layerall_str,
      options_layerall_ecpm,
      options_layerall_ecpm_b,
      options_layerall_unfilled,
      options_layerall_str_guarenteed,
      options_layerall_str_house,
      options_cost,
      options_rev,

      hasLayer2,
    } = this.state;
    return (
      <div>
        <div className={titleClass}>Performance</div>

        <div className="mb-1 flex gap-2 bg-white p-2">
          <div
            style={{
              width: "20px",
              height: "20px",
              backgroundColor: "#F4E6E6",
            }}
          >
            {" "}
          </div>
          {"b>o"}
        </div>

        {/* <div className="flex items-center bg-white">
          <div className="w-1/3"></div>
          <div className="w-1/3"></div>
          <div className="w-1/3">
            {options_rev && (
              <HighchartWrapper
                ref={this.ref14}
                options={options_rev}
              ></HighchartWrapper>
            )}
          </div>
        </div> */}

        <div className="text-lg font-semibold">Layer All</div>
        <LayerAllSection
          ref={this.layerAllRef}
          options_layerall_rrpm={options_layerall_rrpm}
          options_layerall_rrpm_per={options_layerall_rrpm_per}
          options_layerall_rrpm_net={options_layerall_rrpm_net}
          options_layerall_str={options_layerall_str}
          options_layerall_ecpm={options_layerall_ecpm}
          options_layerall_ecpm_b={options_layerall_ecpm_b}
          options_layerall_unfilled={options_layerall_unfilled}
          options_layerall_str_guarenteed={options_layerall_str_guarenteed}
          options_layerall_str_house={options_layerall_str_house}
          options_cost={options_cost}
          options_rev={options_rev}
          handlePointClick={this.handlePointClick}
        ></LayerAllSection>

        <div className="mb-4 flex items-center gap-4 bg-white p-4">
          <button
            className={buttonActionClass}
            onClick={this.handleOpenCompareChart}
          >
            Demand Type Compare Chart
          </button>

          <button
            className={buttonActionClass}
            onClick={this.handleOpenOVDChart}
          >
            O vs O's Shadow vs Godview
          </button>

          {/* <button
            className={buttonActionClass}
            onClick={this.handleOpenGodviewChart}
          >
            Godview Confidence Chart
          </button> */}
        </div>

        <div className="text-lg font-semibold">Layer 1</div>
        <Layer1Section
          ref={this.layer1Ref}
          options_layer1_rrpm={options_layer1_rrpm}
          options_layer1_str={options_layer1_str}
          options_layer1_str_catchall={options_layer1_str_catchall}
          options_layer1_str_guarenteed={options_layer1_str_guarenteed}
          options_layer1_str_house={options_layer1_str_house}
          options_layer1_ecpm={options_layer1_ecpm}
          options_layer1_unfilled={options_layer1_unfilled}
          handlePointClick={this.handlePointClick}
        ></Layer1Section>

        <div className="text-lg font-semibold">Layer 2</div>
        {hasLayer2 ? (
          <Layer2Section
            ref={this.layer2Ref}
            options_layer2_rrpm={options_layer2_rrpm}
            options_layer2_str={options_layer2_str}
            options_layer2_ecpm={options_layer2_ecpm}
            options_layer2_req={options_layer2_req}
            options_layer_loss={options_layer_loss}
            options_layer2_unfilled={options_layer2_unfilled}
            options_layer2_str_guarenteed={options_layer2_str_guarenteed}
            options_layer2_str_house={options_layer2_str_house}
            handlePointClick={this.handlePointClick}
          ></Layer2Section>
        ) : (
          "No layer 2 data"
        )}
      </div>
    );
  }
}

class LayerAllSection extends React.PureComponent {
  constructor(props) {
    super(props);

    this.rrpmRef = React.createRef();
    this.strRef = React.createRef();
    this.ecpmRef = React.createRef();
    this.unfilledRef = React.createRef();
    this.guarenteedStrRef = React.createRef();
    this.houseStrRef = React.createRef();

    this.rrpmDistRef = React.createRef();
    this.costRef = React.createRef();
    this.ecpmDistRef = React.createRef();
  }

  render() {
    const {
      options_layerall_rrpm_per,
      options_cost,
      options_layerall_ecpm_b,
      options_layerall_rrpm,
      options_layerall_str,
      options_layerall_ecpm,
      options_layerall_unfilled,
      options_layerall_str_guarenteed,
      options_layerall_str_house,
    } = this.props;

    return (
      <div className="mb-4">
        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layerall_rrpm_per && (
              <HighchartWrapper
                ref={this.rrpmDistRef}
                options={options_layerall_rrpm_per}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {/* {options_layerall_rrpm_net && (
            <HighchartWrapper
              ref={this.ref12}
              options={options_layerall_rrpm_net}
            ></HighchartWrapper>
          )} */}
            {options_cost && (
              <HighchartWrapper
                ref={this.costRef}
                options={options_cost}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layerall_ecpm_b && (
              <HighchartWrapper
                ref={this.ecpmDistRef}
                options={options_layerall_ecpm_b}
              ></HighchartWrapper>
            )}
          </div>
        </div>

        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layerall_rrpm && (
              <HighchartWrapper
                ref={this.rrpmRef}
                options={options_layerall_rrpm}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layerall_str && (
              <HighchartWrapper
                ref={this.strRef}
                options={options_layerall_str}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layerall_ecpm && (
              <HighchartWrapper
                ref={this.ecpmRef}
                options={options_layerall_ecpm}
              ></HighchartWrapper>
            )}
          </div>
        </div>

        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layerall_str_guarenteed && (
              <HighchartWrapper
                ref={this.guarenteedStrRef}
                options={options_layerall_str_guarenteed}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layerall_unfilled && (
              <HighchartWrapper
                ref={this.unfilledRef}
                options={options_layerall_unfilled}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layerall_str_house && (
              <HighchartWrapper
                ref={this.houseStrRef}
                options={options_layerall_str_house}
              ></HighchartWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Layer1Section extends React.PureComponent {
  constructor(props) {
    super(props);

    this.rrpmRef = React.createRef();
    this.strRef = React.createRef();
    this.ecpmRef = React.createRef();
    this.unfilledRef = React.createRef();
    this.guarenteedStrRef = React.createRef();
    this.houseStrRef = React.createRef();
    this.catchallStrRef = React.createRef();
  }

  render() {
    const {
      options_layer1_rrpm,
      options_layer1_str,
      options_layer1_str_catchall,
      options_layer1_str_guarenteed,
      options_layer1_str_house,
      options_layer1_ecpm,
      options_layer1_unfilled,
    } = this.props;

    return (
      <div>
        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layer1_rrpm && (
              <HighchartWrapper
                ref={this.rrpmRef}
                options={options_layer1_rrpm}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer1_str && (
              <HighchartWrapper
                ref={this.strRef}
                options={options_layer1_str}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer1_ecpm && (
              <HighchartWrapper
                ref={this.ecpmRef}
                options={options_layer1_ecpm}
              ></HighchartWrapper>
            )}
          </div>
        </div>
        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layer1_str_guarenteed && (
              <HighchartWrapper
                ref={this.guarenteedStrRef}
                options={options_layer1_str_guarenteed}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer1_unfilled && (
              <HighchartWrapper
                ref={this.unfilledRef}
                options={options_layer1_unfilled}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer1_str_catchall && (
              <HighchartWrapper
                ref={this.catchallStrRef}
                options={options_layer1_str_catchall}
              ></HighchartWrapper>
            )}
          </div>
        </div>
        <div className="mb-4 flex items-center bg-white">
          <div className="w-1/3">
            {options_layer1_str_house && (
              <HighchartWrapper
                ref={this.houseStrRef}
                options={options_layer1_str_house}
              ></HighchartWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class Layer2Section extends React.PureComponent {
  constructor(props) {
    super(props);

    this.rrpmRef = React.createRef();
    this.strRef = React.createRef();
    this.ecpmRef = React.createRef();
    this.reqRef = React.createRef();
    this.layerLossRef = React.createRef();
    this.unfilledRef = React.createRef();
    this.guarenteedStrRef = React.createRef();
    this.houseStrRef = React.createRef();
  }

  render() {
    const {
      options_layer2_rrpm,
      options_layer2_str,
      options_layer2_ecpm,
      options_layer2_req,
      options_layer_loss,
      options_layer2_unfilled,
      options_layer2_str_guarenteed,
      options_layer2_str_house,
    } = this.props;

    return (
      <div>
        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layer2_rrpm && (
              <HighchartWrapper
                ref={this.rrpmRef}
                options={options_layer2_rrpm}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer2_str && (
              <HighchartWrapper
                ref={this.strRef}
                options={options_layer2_str}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer2_ecpm && (
              <HighchartWrapper
                ref={this.ecpmRef}
                options={options_layer2_ecpm}
              ></HighchartWrapper>
            )}
          </div>
        </div>
        <div className="flex items-center bg-white">
          <div className="w-1/3">
            {options_layer2_req && (
              <HighchartWrapper
                ref={this.reqRef}
                options={options_layer2_req}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer2_unfilled && (
              <HighchartWrapper
                ref={this.unfilledRef}
                options={options_layer2_unfilled}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer_loss && (
              <>
                <HighchartWrapper
                  ref={this.layerLossRef}
                  options={options_layer_loss}
                ></HighchartWrapper>
                <div className="text-center text-xs italic text-gray-700">
                  Layer Loss: 1 - <span className="underline">Layer 2 Req</span>{" "}
                  / <span className="underline">Layer 1 Catchall Req</span>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="mb-4 flex items-center bg-white">
          <div className="w-1/3">
            {options_layer2_str_guarenteed && (
              <HighchartWrapper
                ref={this.guarenteedStrRef}
                options={options_layer2_str_guarenteed}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {options_layer2_str_house && (
              <HighchartWrapper
                ref={this.houseStrRef}
                options={options_layer2_str_house}
              ></HighchartWrapper>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function _createSeriesData(reportsByGroupType, layerNum, metric) {
  let groupTypes = _.keys(reportsByGroupType);
  if (layerNum === 2) {
    // layer 2 has no benchmark
    groupTypes = _.filter(
      groupTypes,
      (gt) => _.startsWith(gt, "d") || gt === "o" || gt === "x"
    );
  }
  if (metric === "req") {
    groupTypes = _.filter(groupTypes, (gt) => _.startsWith(gt, "d"));
  }
  if (metric === "layer_loss") {
    groupTypes = _.filter(groupTypes, (gt) => gt !== "b");
  }
  const numOfDetectors = _.sumBy(groupTypes, (gt) => {
    return _.startsWith(gt, "d") ? 1 : 0;
  });

  const bReports = _.keyBy(reportsByGroupType["b"], "_time");
  let data = [];
  const seriesData = _.map(groupTypes, (gt) => {
    const reports = reportsByGroupType[gt];
    // console.log(reports);
    if (metric === "rrpm_per" || metric === "rrpm") {
      data = _.map(reports, (r) => {
        // b only has one layer!
        // so brpm should always be the same no matter layerall or layer1
        // b does not have layer2
        const bRrpm = _.get(bReports, [r._time, `_layer${layerNum}_rrpm`], 0);
        const rrpm = r[`_layer${layerNum}_rrpm`];
        const rrpm_per =
          bRrpm === 0 ? 0 : _.round((100 * (rrpm - bRrpm)) / bRrpm, 1);

        return {
          x: r._time * 1000,
          y: metric === "rrpm" ? rrpm : rrpm_per,
          prefix: metric === "rrpm" ? `(${rrpm_per}%) ` : "",
        };
      });
    } else if (metric === "ecpm_b") {
      // use b as base, every groupType / b
      data = _.map(reports, (r) => {
        const bEcpm = _.get(bReports, [r._time, `_layer${layerNum}_ecpm`], 0);
        const ecpm = r[`_layer${layerNum}_ecpm`];
        // const ecpm_perc =
        //   bEcpm === 0 ? 0 : _.round((100 * (ecpm - bEcpm)) / bEcpm, 1);
        const ecpm_perc = ecpm / bEcpm;

        return {
          x: r._time * 1000,
          y: ecpm_perc * 100,
        };
      });
    } else if (metric === "ecpm") {
      data = _.map(reports, (r) => {
        const bEcpm = _.get(bReports, [r._time, `_layer${layerNum}_ecpm`], 0);
        const ecpm = r[`_layer${layerNum}_ecpm`];
        // const ecpm_perc =
        //   bEcpm === 0 ? 0 : _.round((100 * (ecpm - bEcpm)) / bEcpm, 1);
        const ecpm_perc = ecpm / bEcpm;

        return {
          x: r._time * 1000,
          y: ecpm,
          prefix: `(${_.round(ecpm_perc * 100, 1)}%) `,
        };
      });
    } else if (metric === "cost") {
      data = _.map(reports, (r) => {
        const costPer = _.round(100 * (r["_cost"] / r["_layerall_rev"]), 2);
        const cost = _.round(r["_cost"], 2);
        const rev = _.round(r["_layerall_rev"], 2);
        return {
          x: r._time * 1000,
          // y: r[`_${metric}`],
          y: costPer,
          prefix: `(cost: $${cost})(rev: $${rev}) `,
        };
      });
    } else if (metric === "rev") {
      data = _.map(reports, (r) => {
        const rev = _.round(r["_layerall_rev"], 2);
        return {
          x: r._time * 1000,
          y: rev,
          // prefix: `(cost: $${cost}) `,
        };
      });
    } else if (metric === "layer_loss") {
      data = _.map(reports, (r) => {
        const layerLoss = r["_layer_loss"];
        return {
          x: r._time * 1000,
          y: layerLoss,
          // prefix: `(cost: $${cost}) `,
        };
      });
    } else if (metric === "unfilled_rate") {
      data = _.map(reports, (r) => {
        const unfilledImp = r[`_layer${layerNum}_unfilled_imp`];
        const req = r[layerNum === 2 ? "_layer2_req" : "_layer1_req"];
        const unfilledRate = _.round((100 * unfilledImp) / req, 2);

        return {
          x: r._time * 1000,
          y: unfilledRate,
          prefix: `(unfilled imp: ${unfilledImp})(req: ${req}) `,
        };
      });
    } else if (metric === "str_house") {
      data = _.map(reports, (r) => {
        const houseImp =
          layerNum === "all"
            ? r[`layer1_house_imp`] + r[`layer2_house_imp`]
            : r[`layer${layerNum}_house_imp`];
        const ppGhostImp =
          layerNum === "all"
            ? r[`layer1_pp_ghost_imp`] + r[`layer2_pp_ghost_imp`]
            : r[`layer${layerNum}_pp_ghost_imp`];

        return {
          x: r._time * 1000,
          y: r[`_layer${layerNum}_${metric}`],
          prefix: `Imp(house: ${houseImp})(ghost: ${ppGhostImp}) `,
        };
      });
    } else {
      data = _.map(reports, (r) => {
        return {
          x: r._time * 1000,
          y: r[`_layer${layerNum}_${metric}`],
        };
      });
    }

    const color = getGroupTypeColor(gt, numOfDetectors);
    return {
      name: gt,
      data,
      color,
      ...getGroupTypeSeriesOptions(gt),
    };
  });

  return _.sortBy(seriesData, (d) => {
    const gt = d.name;
    return GROUP_TYPE_INDEX[gt];
  });
}

function _createChartOptions({
  reportsByGroupType,
  layerNum,
  metric,
  timezone,
  setExtremeFn,
  pointClickFn,
}) {
  const seriesData = _createSeriesData(reportsByGroupType, layerNum, metric);

  let title = "";
  if (metric === "rrpm") title = "Gross rRPM";
  if (metric === "rrpm_per") title = "rRPM (distance to b)";
  if (metric === "rrpm_net") title = "Net rRPM";
  if (metric === "ecpm") title = "eCPM";
  if (metric === "ecpm_b") title = "eCPM (distance to b in %)";
  if (metric === "str") title = "STR";
  if (metric === "str_catchall") title = "Catchall STR";
  if (metric === "str_guarenteed") title = "Guarenteed STR";
  if (metric === "str_house") title = "House + $0 Ghost Imp STR";
  if (metric === "req") title = "Request";
  if (metric === "layer_loss") title = "Layer Loss";
  if (metric === "cost") title = "Cost %";
  if (metric === "rev") title = "Gross Rev";
  if (metric === "unfilled_rate") title = "Unfilled Rate";

  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const plotBands = _createPlotBands(seriesData);

  let min, max;
  if (
    metric === "str" ||
    metric === "str_catchall" ||
    metric === "str_guarenteed" ||
    metric === "str_house" ||
    metric === "layer_loss" ||
    metric === "unfilled_rate" ||
    metric === "cost"
  ) {
    min = 0;
    max = 100;
  }

  let valueDecimalPoint = 2;
  if (metric === "req") valueDecimalPoint = 0;
  if (metric === "rrpm_per" || metric === "ecpm_b") valueDecimalPoint = 1;
  if (metric === "rrpm" || metric === "rrpm_net" || metric === "ecpm")
    valueDecimalPoint = 3;

  const chartTitle = `${title}_layer ${
    layerNum === "all" ? "1 & 2" : layerNum
  }`;
  const options = {
    title: {
      text: layerNum ? chartTitle : title,
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      type: "spline",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)",
      zoomType: "x",
      events: {
        selection: (e) => _selectionEventFn(e, setExtremeFn),
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return tooltipFormatter({
          chart: this,
          timezone,
          isSortByYValue: true,
          // valuePrefix: metric === "cost" ? "$" : "",
          valuePostfix:
            _.includes(metric, "str") ||
            metric === "rrpm_per" ||
            metric === "ecpm_b" ||
            metric === "cost" ||
            metric === "layer_loss" ||
            metric === "unfilled_rate"
              ? "%"
              : "",
          valueDecimalPoint,
        });
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
      series: {
        cursor: metric !== "rrpm_per" && "pointer",
        point: {
          events: {
            click: function () {
              if (metric === "rrpm_per") return;
              if (metric === "ecpm_b") return;
              if (metric === "cost") return;
              if (metric === "layer_loss") return;
              if (metric === "unfilled_rate") return;
              return pointClickFn({
                datetime: this.x,
                groupType: this.series.name,
                layerNum,
                metric,
              });
            },
          },
        },
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        min,
        max,
      },
    ],
    xAxis: {
      plotBands,
      // type: "datetime",
      ..._getXAxisConfigDateTime({ timezone }),
    },
    series: seriesData,
  };

  return options;
}

function _createPlotBands(reports) {
  const reportsByGroupType = _.keyBy(reports, "name");
  const bData = _.get(reportsByGroupType["b"], "data");
  const oData = _.get(reportsByGroupType["o"], "data");
  const data = _.map(bData, (d) => {
    return {
      time: d.x,
      b: d.y,
      o: _.get(_.find(oData, { x: d.x }), "y"),
    };
  });

  const times = _.sortBy(_.map(data, "time"));
  const halfTime = (times[1] - times[0]) / 2;

  const plotBands = _.reduce(
    data,
    (result, d) => {
      if (d.b > d.o) {
        const from = d.time - halfTime;
        const to = d.time + halfTime;
        result.push({
          color: "#f4e6e6",
          from,
          to,
        });
      }
      return result;
    },
    []
  );
  if (_.isEmpty(plotBands)) return null;

  return plotBands;
}

function _getXAxisConfigDateTime({ timezone }) {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value).tz(timezone);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _selectionEventFn(e, setExtremeFn) {
  e.preventDefault();
  // when reset button clicked
  if (e.resetSelection) {
    setExtremeFn({ min: null, max: null });
  }

  // when dragging zoom on xAxis
  if (e.xAxis) {
    const min = e.xAxis[0].min;
    const max = e.xAxis[0].max;
    // console.log(min, max, new Date(min), new Date(max));
    setExtremeFn({ min, max });
  }
}

export default AnatomyCstPerformanceSection;
