import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import VaultAccountViewByNetworkModal from "../../vault/VaultAccountViewByNetworkModal";
import NetworkEnsureResourcesButton from "./NetworkEnsureResources";
import NetworkStatusIndicator from "components/common/NetworkStatusIndicator";
import GoToPageSelector from "components/common/GoToPageSelector";
import UpdateNetworkBillableTypes from "./UpdateNetworkBillableTypes";

const thClass =
  "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
const tdClass = "px-4 py-2";

const buttonActionClass =
  "px-2 py-1 bg-gray-200 text-xs rounded border border-gray-400 shadow hover:bg-gray-300 text-gray-900 font-semibold";

class NetworksTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAccountModalOpen: false,
      currentNetwork: null,
    };

    this.handleOpenAccountModal = this.handleOpenAccountModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.getNetworkActionPaths = this.getNetworkActionPaths.bind(this);
  }

  handleCloseModal() {
    this.setState({
      isAccountModalOpen: false,
      currentNetwork: null,
    });
  }

  handleOpenAccountModal(network) {
    this.setState({ isAccountModalOpen: true, currentNetwork: network });
  }

  getNetworkActionPaths(networkId) {
    return [
      {
        name: "Update Ad Serving Cost",
        path: `/ops-mgmt/account/network/${networkId}/update-ad-serving-cost`,
      },
      {
        name: "UPR Segmentation",
        path: `/ops-mgmt/account/network/${networkId}/upr-segmentation`,
      },
      {
        name: "Custom UPR",
        path: `/ops-mgmt/account/network/${networkId}/upr-setting`,
      },
      {
        name: "CST Config",
        path: `/ops-mgmt/account/network/${networkId}/cst-config`,
      },
      {
        name: "Network Features",
        path: `/ops-mgmt/account/network/${networkId}/features`,
      },
      {
        name: "Code Snippet",
        path: `/ops-mgmt/account/network/${networkId}/code-snippet`,
      },
      {
        name: "Virtual Placements",
        path: `/ops-mgmt/account/network/${networkId}/virtual-placement/list`,
      },
    ];
  }

  render() {
    const { items, hideAction } = this.props;
    const { isAccountModalOpen, currentNetwork } = this.state;

    return (
      <>
        <table className="shadow divide-y min-w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "20%" }}>
                Network
              </th>
              <th className={thClass + " text-right"}>Cost CPM</th>
              <th className={thClass + " text-right"}>Free Quota</th>
              <th className={thClass + " text-left"}>Props</th>

              {!hideAction && (
                <th className={thClass + " text-right"}>Action</th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y bg-white text-sm">
            {items.map((item) => {
              return (
                <tr key={item.networkId} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    <span className="text-lg font-semibold text-gray-800">
                      {item.networkId} - {item.name}
                    </span>
                    <div className="flex items-center gap-2">
                      <NetworkStatusIndicator
                        status={item.status}
                      ></NetworkStatusIndicator>
                      <div className="text-xs font-medium tracking-wide text-gray-600">
                        {item.status}
                      </div>
                    </div>
                  </td>

                  <td className={tdClass + " text-right"}>
                    <div className="border-b pb-1">
                      <span className="text-xs text-gray-600">algo </span>
                      <NumberFormat
                        value={item.algoCostCpm}
                        prefix="$"
                        format="0,0.0000"
                      ></NumberFormat>
                      <div className="text-xs leading-none text-gray-600">
                        USD
                      </div>
                    </div>

                    <div>
                      {item.adServingCost ? (
                        <>
                          <NumberFormat
                            value={item.adServingCost.costCpm}
                            prefix="$"
                            format="0,0.0000"
                          ></NumberFormat>
                          <div
                            className={`text-xs leading-none ${
                              !item.adServingCost.costCpmCurrency ||
                              item.adServingCost.costCpmCurrency === "USD"
                                ? "text-gray-600"
                                : "text-purple-600"
                            }`}
                          >
                            {item.adServingCost.costCpmCurrency || "USD"}
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td className={tdClass + " text-right"}>
                    {item.adServingCost ? (
                      <NumberFormat
                        value={item.adServingCost.freeQuota}
                        format="0,0a"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className={tdClass + " text-left"}>
                    <div className="my-1 text-xs text-gray-900">
                      <span>
                        Billable Demand Types ({item.billableDemandTypes.length}
                        ):{" "}
                      </span>
                      <DemandTypesSection
                        demandTypes={item.billableDemandTypes}
                      ></DemandTypesSection>
                    </div>

                    <div className="mt-2 mb-1 text-xs text-gray-900">
                      <span>
                        Billable Request Types (
                        {item.billableRequestTypes.length}):{" "}
                      </span>
                      <RequestTypesSection
                        requestTypes={item.billableRequestTypes}
                      ></RequestTypesSection>
                    </div>
                  </td>

                  {!hideAction && (
                    <td className={tdClass + " text-right"}>
                      {
                        // item.status !== "BUSINESS_TERMINATED" &&
                        item.accountType !== "ARCHIVED" &&
                        item.accountType !== "PROCESSING" &&
                        item.accountType !== "FAILED" ? (
                          <>
                            <GoToPageSelector
                              goToPaths={this.getNetworkActionPaths(
                                item.networkId
                              )}
                            ></GoToPageSelector>
                            <br></br>
                          </>
                        ) : (
                          "-"
                        )
                      }

                      <div className="flex items-center justify-end gap-2">
                        <UpdateNetworkBillableTypes
                          networkId={item.networkId}
                          billableDemandTypes={item.billableDemandTypes}
                          billableRequestTypes={item.billableRequestTypes}
                        ></UpdateNetworkBillableTypes>

                        <NetworkEnsureResourcesButton
                          networkId={item.networkId}
                        ></NetworkEnsureResourcesButton>

                        <button
                          type="button"
                          className={buttonActionClass}
                          onClick={() => this.handleOpenAccountModal(item)}
                        >
                          GAM Account
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>

        {isAccountModalOpen && (
          <VaultAccountViewByNetworkModal
            network={currentNetwork}
            isOpenModal={isAccountModalOpen}
            handleClose={this.handleCloseModal}
          ></VaultAccountViewByNetworkModal>
        )}
      </>
    );
  }
}

class DemandTypesSection extends React.PureComponent {
  render() {
    const { demandTypes } = this.props;
    if (!demandTypes) {
      return "-";
    }

    const dts = demandTypes;

    return (
      // <div className="flex gap-2 whitespace-no-wrap overflow-x-auto w-full">
      <div className="-mb-1">
        {dts.map((d) => {
          return (
            <div
              key={d}
              className="rounded mr-2 mb-1 inline-block bg-gray-300 px-2 text-xs text-gray-700"
            >
              {d}
            </div>
          );
        })}
      </div>
    );
  }
}

class RequestTypesSection extends React.PureComponent {
  render() {
    const { requestTypes } = this.props;
    if (!requestTypes) {
      return "-";
    }

    const dts = requestTypes;

    return (
      // <div className="flex gap-2 whitespace-no-wrap overflow-x-auto w-full">
      <div className="-mb-1">
        {dts.map((d) => {
          return (
            <div
              key={d}
              className="rounded mr-2 mb-1 inline-block bg-gray-300 px-2 text-xs text-gray-700"
            >
              {d}
            </div>
          );
        })}
      </div>
    );
  }
}

export default NetworksTable;
