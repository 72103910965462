import React from "react";
import _ from "lodash";
import Select from "react-select";
// import AsyncSelect from "react-select/async";

import { NetworkAPI } from "apis";

let cache = {};

class CustomTargetingKeysSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,

      isLoading: false,
      errMsg: null,
    };

    this.asyncFn = null;

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const { networkId, customTargetingKeys, keyId } = this.props;
    if (!networkId) {
      return;
    }

    if (customTargetingKeys) {
      let options = _.map(customTargetingKeys, (r) => {
        return {
          value: r.id,
          id: r.id,
          name: r.name,
          reportableType: r.reportableType,
          label: `${r.id} - ${r.name} (Reportable Type: ${r.reportableType})`,
        };
      });

      if (keyId) {
        const selectedValue = _.find(options, (o) => {
          return o.id === _.parseInt(keyId); // keyId could be string
        });
        this.setState({
          selectedValue,
          isLoading: false,
        });
        if (!this.props.isReadonly) {
          this.props.handleOnChange(selectedValue);
        }
      }

      this.setState({
        options,
        errMsg: null,
        isLoading: false,
      });
    } else {
      this.asyncFn = await this.queryCustomTargetingKeys();
    }
  }

  async queryCustomTargetingKeys() {
    const { networkId, keyId } = this.props;

    this.setState({ isLoading: true });
    try {
      let items = [];
      if (cache[networkId]) {
        items = cache[networkId];
      } else {
        const r = await NetworkAPI.getCustomTargetingKeys({ networkId });
        items = r.items;
        cache[networkId] = items;
      }
      let options = _.map(items, (r) => {
        return {
          value: r.id,
          id: r.id,
          name: r.name,
          reportableType: r.reportableType,
          label: `${r.id} - ${r.name} (Reportable Type: ${r.reportableType})`,
        };
      });

      if (keyId) {
        const selectedValue = _.find(options, (o) => {
          return o.id === _.parseInt(keyId); // keyId could be string
        });
        this.setState({
          selectedValue,
          isLoading: false,
        });
        if (!this.props.isReadonly) {
          this.props.handleOnChange(selectedValue);
        }
      }

      this.setState({
        items,
        options,
        errMsg: null,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: `Error query custom targeting keys: ${err.toString()}`,
        isLoading: false,
      });
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });
    this.props.handleOnChange(option);

    // if (this.props.isMulti) {
    //   const ids = _.map(option, (o) => {
    //     return o.value;
    //   });
    //   this.props.handleOnChange(ids);
    // } else {
    //   const valueKey = this.props.valueKey || "networkId";
    //   const item = _.find(this.state.items, { [valueKey]: option.value });
    //   this.props.handleOnChange(item);
    // }
  }

  componentWillUnmount() {
    if (this.asyncFn) {
      this.asyncFn.cancel();
    }
  }

  // async loadOptions(inputValue, callback) {
  //   const { networkId } = this.props;
  //   const { keys } = await NetworkAPI.getCustomTargetingKeys({
  //     networkId: 356,
  //   });
  //   console.log(keys);
  //   let options = _.map(keys, (r) => {
  //     return {
  //       value: r.id,
  //       label: `${r.id} - ${r.name} (${r.type})`,
  //     };
  //   });
  //   return options;
  // }

  render() {
    const { options, selectedValue, errMsg, isLoading } = this.state;

    if (errMsg) {
      return <div className="bg-red-100 py-1 px-2 text-red-800">{errMsg}</div>;
    }

    return (
      <>
        {/* Use this if query by input */}
        {/* <AsyncSelect
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions
        ></AsyncSelect> */}
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
          isClearable
          isLoading={isLoading}
          isDisabled={this.props.isReadonly}
        />
      </>
    );
  }
}

export default CustomTargetingKeysSelector;
