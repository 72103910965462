import React from "react";
import _ from "lodash";

import ClickToCopyWrapper from "components/common/ClickToCopyWrapper";

class UPRSegmentationConfigTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.getPriceSuggestionById = this.getPriceSuggestionById.bind(this);
  }

  getPriceSuggestionById(id) {
    const { priceSuggestions } = this.props;
    if (!priceSuggestions) return;

    return _.get(priceSuggestions, ["suggestionsBySegment", id, "parent"]);
  }

  render() {
    const {
      items,
      priceSuggestions,
      // networkInfo,
      handleOpenEdit,
      handleRemoveRule,
      handleOpenDuplicate,
      handleOpenUnitSettings,
    } = this.props;

    if (items.length === 0) {
      return <div>No UPR Segmentation</div>;
    }

    return (
      <div style={{ marginBottom: "600px" }}>
        <div className="pt-4 font-medium">{items.length} rules</div>

        <table className="border shadow table w-full text-sm">
          <thead className="border-b bg-gray-200 text-xs text-blue-800">
            <tr>
              <th className="border py-1 px-2 text-right">ID</th>
              <th className="border py-1 px-2 text-left ">Name</th>
              <th className="border py-1 px-2 text-left ">Optimization</th>
              <th className="border py-1 px-2 text-center">
                Unit<br></br>Settings
              </th>
              <th className="border py-1 px-2 text-left ">Prices</th>
              <th className="border py-1 px-2 text-right ">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white  text-gray-900">
            {items.map((item) => {
              const pSuggestions = this.getPriceSuggestionById(item.id);
              const isCstV2 = _.startsWith(item.optimization, "cstv2");

              return (
                <tr key={item.id} className="border-b hover:bg-gray-100">
                  <td className="border py-1 px-2 text-right">{item.id}</td>

                  <td className="border py-1 px-2 text-left font-medium">
                    {item.name}
                    {/* 
                    {item.unit_segmented_price_settings && (
                      <div className="border-t -mx-2 my-2 px-2 pt-2">
                        <div className="text-xs font-medium text-gray-700">
                          Unit Settings (
                          {item.unit_segmented_price_settings.length})
                        </div>

                        <table className="border table w-full text-xs font-normal text-gray-700">
                          <tbody>
                            {item.unit_segmented_price_settings.map((u) => {
                              return (
                                <tr key={u.id}>
                                  <td className="border px-1">
                                    {u.id} {u.name}
                                  </td>
                                  <td className="border px-1">
                                    {u.units.length} units
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )} */}

                    {/* TODO */}
                    {/* <UprTargetingPopover
                      targetingSection={item.targetingSection}
                      triggerElement={
                        <div className="cursor-pointer hover:underline">
                          {JSON.stringify(Object.keys(item.targetingSection))}
                        </div>
                      }
                    ></UprTargetingPopover> */}
                  </td>

                  <td className="border py-1 px-2 text-center font-semibold">
                    {item.optimization}
                  </td>

                  <td className="border py-1 px-2 text-center">
                    {isCstV2 ? (
                      <button
                        type="button"
                        className="rounded bg-blue-100 py-2 px-4 font-semibold text-blue-600 hover:bg-blue-200"
                        onClick={() => handleOpenUnitSettings(item)}
                      >
                        {/* Unit Settings ( */}
                        {item.unit_segmented_price_settings
                          ? item.unit_segmented_price_settings.length
                          : 0}
                        {/* ) */}
                      </button>
                    ) : (
                      "-"
                    )}
                  </td>

                  <td className="border py-1 px-1 text-left text-xs">
                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Prices:
                        {item.optimization !== "dt" && (
                          <span>({item.prices.length})</span>
                        )}
                      </span>{" "}
                      <br></br>
                      {item.optimization !== "dt" ? (
                        <div>{item.prices.join(", ")}</div>
                      ) : (
                        "-"
                      )}
                      {priceSuggestions && (
                        <div className=" text-orange-700">
                          <span className="font-semibold">
                            Suggestion:({pSuggestions && pSuggestions.length})
                          </span>{" "}
                          <br></br>
                          <div className="flex items-center gap-1">
                            {pSuggestions ? pSuggestions.join(", ") : "-"}
                            <ClickToCopyWrapper
                              copyText={pSuggestions.join(", ")}
                            ></ClickToCopyWrapper>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Detector Prices: ({item.detector_prices.length})
                      </span>
                      <br></br> {item.detector_prices.join(", ")}
                    </div>

                    <div className="border-b mb-1">
                      <span className="font-semibold text-gray-800">
                        Catchall Prices:{" "}
                        {item.catchall_prices && (
                          <>({item.catchall_prices.length})</>
                        )}
                      </span>
                      <br></br>{" "}
                      {item.catchall_prices
                        ? item.catchall_prices.join(", ")
                        : "-"}
                    </div>

                    <div>
                      <span className="font-semibold text-gray-800">
                        X Prices:{" "}
                        {item.x_prices && <>({item.x_prices.length})</>}
                      </span>
                      <br></br> {item.x_prices ? item.x_prices.join(", ") : "-"}
                    </div>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-2 text-right">
                    <div className="flex items-center justify-end gap-4">
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenEdit(item)}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleOpenDuplicate(item)}
                      >
                        Duplicate
                      </button>
                      <button
                        type="button"
                        className="text-blue-600 hover:underline"
                        onClick={() => handleRemoveRule(item)}
                      >
                        Remove
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default UPRSegmentationConfigTable;
