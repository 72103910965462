import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts"; // to get highchart functions

import {
  GROUP_TYPE_INDEX,
  getSegmentPriceSectionData,
} from "./AnatomyCstReportHelper";
import HighchartWrapper from "components/common/HighchartWrapper";
import {
  getGroupTypeColor,
  getGroupTypeSeriesOptions,
  tooltipFormatter,
} from "./AnatomyHighchartHelper";

const titleClass = "block text-2xl text-gray-800 font-bold mb-1 mt-2";
class AnatomyCstPriceSection extends React.Component {
  constructor(props) {
    super(props);

    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();

    this.state = {
      options: null,
      optionsImp: null,
      optionsReq: null,
      timezone: this.props.timezone,
    };

    this.clearData = this.clearData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.redrawCharts = this.redrawCharts.bind(this);
    this.setExtremes = this.setExtremes.bind(this);
  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    // console.log(prevProps.uprSegmentId, this.props.uprSegmentId);
    if (prevProps.uprSegmentId !== this.props.uprSegmentId) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.dateRangeNum, this.props.dateRangeNum);
    if (prevProps.dateRangeNum !== this.props.dateRangeNum) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.aggregation, this.props.aggregation);
    if (prevProps.aggregation !== this.props.aggregation) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.includeToday, this.props.includeToday);
    if (prevProps.includeToday !== this.props.includeToday) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.isIncludeGhostImp, this.props.isIncludeGhostImp);
    if (prevProps.isIncludeGhostImp !== this.props.isIncludeGhostImp) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.demandTypes, this.props.demandTypes);
    if (prevProps.demandTypes !== this.props.demandTypes) {
      this.refreshData();
      this.redrawCharts();
    }

    // console.log(prevProps.selectedGroupTypes, this.props.selectedGroupTypes);
    if (prevProps.selectedGroupTypes !== this.props.selectedGroupTypes) {
      this.redrawCharts();
    }

    // console.log(prevProps.timezoneType, this.props.timezoneType);
    if (prevProps.timezoneType !== this.props.timezoneType) {
      this.redrawChartTimezone();
    }

    // set extremes
    if (prevProps.st !== this.props.st || prevProps.et !== this.props.et) {
      this.setExtremes({ st: this.props.st, et: this.props.et });
    }
  }

  setExtremes({ st, et }) {
    // The better zoom! Update min max on xAxis, instead of setExtremes
    // when min, max is null, resets xAxis
    for (let i = 1; i <= 3; ++i) {
      const chart = this[`ref${i}`].current.ref.current.chart;
      chart.xAxis[0].update({ min: st, max: et });

      // hide/show reset zoom button
      if (!st && !et) {
        if (chart.resetZoomButton) {
          chart.resetZoomButton.hide();
        }
      } else {
        if (chart.resetZoomButton) {
          chart.resetZoomButton.show();
        } else {
          chart.showResetZoom();
        }
      }
    }
  }

  refreshData() {
    const cb = this.updateData;
    this.clearData(cb);
  }

  clearData(cb) {
    // console.log("clear data");
    this.setState(
      {
        options: null, // price chart is not affected, but to clear zoom clear this anyway
        optionsImp: null,
        optionsReq: null,
      },
      cb
    );
  }

  updateData() {
    const { data, uprSegmentId, demandTypes, timezone, timezoneType } =
      this.props;
    // console.log("update data", uprSegmentId, data);

    let { priceSectionData, layer1Data } = getSegmentPriceSectionData({
      reports: data,
      demandTypes,
    });

    let tz = timezone;
    if (timezoneType === "TAIPEI") {
      tz = "Asia/Taipei";
    }

    const setExtremeFn = this.props.handleSetExtremes;
    this.setState({
      options: _createPriceChartOptions(priceSectionData, tz, setExtremeFn),
      optionsImp: _createImpChartOptions(layer1Data, tz, setExtremeFn),
      optionsReq: _createReqChartOptions(layer1Data, tz, setExtremeFn),
    });
  }

  redrawChartTimezone() {
    const { timezone, timezoneType } = this.props;
    console.log("redraw chart timezone", timezone, timezoneType);

    let tz = timezone;
    if (timezoneType === "TAIPEI") {
      tz = "Asia/Taipei";
    }
    const timezoneOffset = -moment.tz(tz).utcOffset();

    const options = { ...this.state.options, time: { timezoneOffset } };
    const optionsImp = { ...this.state.optionsImp, time: { timezoneOffset } };
    const optionsReq = { ...this.state.optionsReq, time: { timezoneOffset } };

    this.setState({
      options,
      optionsImp,
      optionsReq,
      timezone: tz,
    });
  }

  redrawCharts() {
    const { selectedGroupTypes } = this.props;

    setTimeout(() => {
      // console.log(this.ref1, this.ref1.current.ref.current.chart);
      // console.log(this.ref2, this.ref2.current.ref.current.chart);
      // console.log(this.ref3, this.ref3.current.ref.current.chart);

      const trendCharts = [
        this.ref1.current.ref.current.chart,
        this.ref2.current.ref.current.chart,
        this.ref3.current.ref.current.chart,
      ];

      trendCharts.forEach((chart) => {
        const series = chart.series;

        for (let s of series) {
          if (_.indexOf(selectedGroupTypes, s.name) !== -1) {
            s.show();
          } else {
            s.hide();
          }
        }
      });
    }, 100);
  }

  render() {
    const { options, optionsImp, optionsReq } = this.state;
    // console.log("optionsImp", optionsImp);
    // console.log("optionsReq", optionsReq);
    // console.log("options", options);
    return (
      <div>
        <div className={titleClass}>Price and Data Integrity</div>

        <div className="flex items-center">
          <div className="w-1/3">
            {options && (
              <HighchartWrapper
                ref={this.ref1}
                options={options}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {optionsImp && (
              <HighchartWrapper
                ref={this.ref2}
                options={optionsImp}
              ></HighchartWrapper>
            )}
          </div>
          <div className="w-1/3">
            {optionsReq && (
              <HighchartWrapper
                ref={this.ref3}
                options={optionsReq}
                timezone={this.state.timezone}
              ></HighchartWrapper>
            )}
          </div>
        </div>
        {/* <div className="flex items-center">
          <div className="w-1/3"></div>
          <div className="w-1/3"></div>
          <div className="w-1/3">
            {optionsReq && (
              <HighchartWrapper
                ref={this.ref3}
                options={optionsReq}
                timezone={this.state.timezone}
              ></HighchartWrapper>
            )}
          </div>
        </div> */}
      </div>
    );
  }
}

function _createPriceSeriesData(reportsByGroupType) {
  const groupTypes = _.keys(reportsByGroupType);
  const numOfDetectors = _.sumBy(groupTypes, (gt) => {
    return _.startsWith(gt, "d") ? 1 : 0;
  });
  const seriesData = _.map(groupTypes, (gt) => {
    const reports = reportsByGroupType[gt];
    const sortedReports = _.sortBy(reports, "time");
    const data = _.map(sortedReports, (r) => {
      return {
        x: r.time * 1000,
        y: r.price,
      };
    });

    const color = getGroupTypeColor(gt, numOfDetectors);
    const d = {
      name: gt,
      type: "spline",
      data,
      color,
      ...getGroupTypeSeriesOptions(gt),
    };

    return d;
  });

  return _.sortBy(seriesData, (d) => {
    const gt = d.name;
    return GROUP_TYPE_INDEX[gt];
  });
}

function _createPriceChartOptions(reportsByGroupType, timezone, setExtremeFn) {
  const seriesData = _createPriceSeriesData(reportsByGroupType);
  // console.log("seriesData", seriesData);

  const timezoneOffset = -moment.tz(timezone).utcOffset();
  const options = {
    title: {
      text: "Price",
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      zoomType: "x",
      events: {
        selection: (e) => _selectionEventFn(e, setExtremeFn),
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter() {
        return tooltipFormatter({
          chart: this,
          timezone,
          valueDecimalPoint: 2,
        });
      },
    },
    xAxis: { ..._getXAxisConfigDateTime({ timezone }) },
    yAxis: [
      {
        title: {
          text: "$",
        },
      },
    ],
    series: seriesData,
  };

  return options;
}

function _createImpSeriesData(reportsByGroupType) {
  const numOfDetectors = _.sumBy(_.keys(reportsByGroupType), (gt) => {
    return _.startsWith(gt, "d") ? 1 : 0;
  });
  const seriesData = _.map(reportsByGroupType, (reports, groupType) => {
    const data = _.map(reports, (r) => {
      return {
        x: r._time * 1000,
        y: r._layer1_imp,
      };
    });
    return {
      name: groupType,
      type: "spline",
      marker: {
        enabled: false,
      },
      color: getGroupTypeColor(groupType, numOfDetectors),
      data,
    };
  });

  return _.sortBy(seriesData, (d) => {
    const gt = d.name;
    return GROUP_TYPE_INDEX[gt];
  });
}

function _createImpChartOptions(reportsByGroupType, timezone, setExtremeFn) {
  const seriesData = _createImpSeriesData(reportsByGroupType);
  const timezoneOffset = -moment.tz(timezone).utcOffset();

  const options = {
    title: {
      text: "Impressions (Detector L1)",
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      zoomType: "x",
      events: {
        selection: (e) => _selectionEventFn(e, setExtremeFn),
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      formatter() {
        return tooltipFormatter({
          chart: this,
          timezone,
        });
      },
    },
    xAxis: { ..._getXAxisConfigDateTime({ timezone }) },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    series: seriesData,
  };

  return options;
}

function _createReqSeriesData(reportsByGroupType) {
  const numOfDetectors = _.sumBy(_.keys(reportsByGroupType), (gt) => {
    return _.startsWith(gt, "d") ? 1 : 0;
  });

  const d1ReqByDate = _.keyBy(reportsByGroupType["d1"], "_time");

  const seriesData = _.map(reportsByGroupType, (reports, groupType) => {
    const data = _.map(reports, (r) => {
      const d1Req = _.get(d1ReqByDate, [r._time, "layer1_req"]);
      const currentReq = r.layer1_req;
      const dist = currentReq - d1Req;
      const distRatio = _.round((dist / d1Req) * 100, 2);
      return {
        x: r._time * 1000,
        y: r.layer1_req,
        d1Dist: dist,
        distRatio,
      };
    });
    return {
      name: groupType,
      type: "spline",
      marker: {
        enabled: false,
      },
      color: getGroupTypeColor(groupType, numOfDetectors),
      data,
    };
  });

  return _.sortBy(seriesData, (d) => {
    const gt = d.name;
    return GROUP_TYPE_INDEX[gt];
  });
}

function _createReqChartOptions(reportsByGroupType, timezone, setExtremeFn) {
  const seriesData = _createReqSeriesData(reportsByGroupType);
  const timezoneOffset = -moment.tz(timezone).utcOffset();

  const options = {
    title: {
      text: "Client Requests",
    },
    time: {
      timezoneOffset: timezoneOffset,
    },
    chart: {
      zoomType: "x",
      events: {
        selection: (e) => _selectionEventFn(e, setExtremeFn),
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: "rgba(255,255,255,1)", // use full white to make looking at all those numbers easier to read
      formatter() {
        return _tooltipFormatterReq({
          chart: this,
          timezone,
        });
      },
    },
    xAxis: { ..._getXAxisConfigDateTime({ timezone }) },
    yAxis: [
      {
        title: {
          text: "",
        },
      },
    ],
    series: seriesData,
  };

  return options;
}

function _getXAxisConfigDateTime({ timezone }) {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value).tz(timezone);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> ${m.format("M")}`;
        }
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

function _selectionEventFn(e, setExtremeFn) {
  e.preventDefault();
  // when reset button clicked
  if (e.resetSelection) {
    setExtremeFn({ min: null, max: null });
  }

  // when dragging zoom on xAxis
  if (e.xAxis) {
    const min = e.xAxis[0].min;
    const max = e.xAxis[0].max;
    // console.log(min, max, new Date(min), new Date(max));
    setExtremeFn({ min, max });
  }
}

// Sort By Y Value!
export function _tooltipFormatterReq({
  chart,
  timezone,
  isSortByYValue = false,
  valuePrefix = "",
  valuePostfix = "",
  valueDecimalPoint = 0,
}) {
  let sortedPoints = chart.points;
  if (isSortByYValue) {
    sortedPoints = chart.points.sort((pointA, pointB) => pointB.y - pointA.y);
  }

  return `
    <div style="text-align: center; font-size: 12px; margin-bottom: 2px;">
      ${moment.tz(chart.x, timezone).format("YYYY-MM-DD HH:mm")}
    </div>
    
    <div>
      <table style="margin: 0 auto;">
        <tbody>
          <tr>
            <td colspan="5" style="text-align: right;">(distance to d1)</td>
          </tr>
          ${sortedPoints
            .map((point) => {
              const isOB =
                point.series.name === "b" ||
                point.series.name === "o" ||
                point.series.name === "x";
              return `
                <tr>
                  <td>
                    <span style="color: ${point.color};">\u25CF</span>
                  </td>
                  <td style="text-align: right; padding-right: 3px; ${
                    isOB && `font-weight: 600;`
                  }">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      ${point.series.name}
                    </span>:
                  </td>
                  <td style="text-align: right; font-weight: 600;">
                    <span style="${isOB && "border-bottom: 1px solid black;"}">
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.prefix || valuePrefix
                      }</span>
                      ${Highcharts.numberFormat(point.y, valueDecimalPoint)}
                      <span style="font-weight: 400; color: 'light-gray';">${
                        point.point.postfix || valuePostfix
                      }</span>
                    </span>
                  </td>

                  <td style="text-align: right; color: gray;">
                    ${point.point.d1Dist}
                  </td>

                  <td style="text-align: right; ${
                    point.point.distRatio <= -5 && "color: red;"
                  }">
                    (${point.point.distRatio}%)
                  </td>
                </tr>
              `;
            })
            .join("")}
        </tbody>
      </table>
    </div>
  `;
}

export default AnatomyCstPriceSection;
