import React from "react";
import _ from "lodash";
import Select from "react-select";

import { NetworkAPI } from "apis";

let cache = {};

class CustomTargetingValuesSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      items: [],
      options: [],
      selectedValue: null,

      errMsg: null,
    };

    this.asyncFn = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    const { networkId, keyId } = this.props;
    if (!networkId) {
      return;
    }

    if (!keyId) {
      return;
    }

    this.asyncFn = await this.queryCustomKeyValues();
  }

  async queryCustomKeyValues() {
    const { networkId, keyId, managedValueIds, benchmarkValueIds } = this.props;
    this.setState({ isLoading: true });

    try {
      let items = [];
      const cacheKey = `${networkId}_${keyId}`;
      if (cache[cacheKey]) {
        items = cache[cacheKey];
      } else {
        const r = await NetworkAPI.getCustomTargetingValues({
          networkId,
          ids: [keyId],
        });
        items = r.items;
        cache[cacheKey] = items;
      }
      let options = _.map(items, (r) => {
        return {
          value: r.id,
          id: r.id,
          name: r.name,
          label: `${r.id} - ${r.name}${
            r.displayName ? ` (${r.displayName})` : ""
          }`,
        };
      });

      if (managedValueIds && managedValueIds.length > 0) {
        const selectedValue = _.filter(options, (option) => {
          const mids = _.map(managedValueIds, _.parseInt);
          return _.indexOf(mids, option.id) !== -1;
        });

        this.setState({ selectedValue });
        !this.props.isReadonly && this.props.handleOnChange(selectedValue);
      } else if (benchmarkValueIds && benchmarkValueIds.length > 0) {
        const selectedValue = _.filter(options, (option) => {
          return _.indexOf(benchmarkValueIds, option.id) !== -1;
        });

        this.setState({ selectedValue });
        !this.props.isReadonly && this.props.handleOnChange(selectedValue);
      }

      this.setState({
        items,
        options,
        errMsg: null,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: `Error query custom targeting keys: ${err.toString()}`,
      });
    }

    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    if (_.parseInt(prevProps.keyId) !== _.parseInt(this.props.keyId)) {
      await this.queryCustomKeyValues();
      this.setState({
        selectedValue: null,
      });
      this.props.handleOnChange(null);
    }
  }

  componentWillUnmount() {
    if (this.asyncFn) {
      this.asyncFn.cancel();
    }
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });
    this.props.handleOnChange(option);
  }

  handleInputChange(input) {
    if (_.isEmpty(input)) return;
    // Check if there is seperator
    // user can paste a list of unit ids
    if (input.includes(",")) {
      let parts = input.split(",");
      parts = _.map(_.compact(parts), _.trim);
      if (parts.length > 0) {
        // check if this is a list of ids
        console.log(parts, this.state.options);
        const selectedValue = _.filter(this.state.options, (option) => {
          return _.indexOf(parts, option.id.toString()) != -1; // id is integer in options
        });

        if (selectedValue.length > 0) {
          const newValues = _.uniqBy(
            [...(this.state.selectedValue || []), ...selectedValue],
            "id"
          );
          this.handleChange(newValues);
          return "";
        }
      }
    }

    return input;
  }

  render() {
    const { managedValueIds } = this.props;
    const { options, selectedValue, errMsg, isLoading } = this.state;

    if (errMsg) {
      return (
        <>
          <div>{managedValueIds && managedValueIds.join(", ")}</div>
          <div className="bg-red-100 py-1 px-2 text-red-800">{errMsg}</div>
        </>
      );
    }

    return (
      <>
        {/* Use this if query by input */}
        {/* <AsyncSelect
          cacheOptions={true}
          loadOptions={this.loadOptions}
          defaultOptions
        ></AsyncSelect> */}
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isMulti={this.props.isMulti}
          isClearable
          isLoading={isLoading}
          onInputChange={this.handleInputChange}
          isDisabled={this.props.isReadonly}
        />
      </>
    );
  }
}

export default CustomTargetingValuesSelector;
