import _ from "lodash";
import pako from "pako";

import {
  api,
  fetchRespJson,
  postData,
  // fetchRespJsonWithoutCredentials,
} from "apis/base";

export default {
  // network
  getNetworkResources,
  getNetworkInfo,
  getNetworksInfo,
  getNetworkCustomUpr,
  getUnitsByNetworkId,
  updateNetworkCustomUpr,
  updateNetworkAdServingCost,
  getNetworks,
  getYieldSets,
  getNetworkGamInfo,

  getNetworkUprSegmentation,
  getNetworkUprSegmentationByUnitId,
  getInventoryRegistry,
  createNetworkUprSegmentation,
  getFirstLayerPriceSuggestion,
  getCstConfig,
  createCstConfig,

  getCustomTargetingKeys,
  getCustomTargetingValues,
  getCstEstimateResource,
  getRegisteredInventorySuggestion,

  getFeaturesSchema,
  getDefaultNetworkFeatures,
  getRawNetworkFeatures,
  createNetworkFeatures,
  getRawPublisherFeatures,
  createPublisherFeatures,

  getCodeSnippetBuilds,
  getBuildDetails,
  getBuildInput,
  getBuildPackages,
  createNewBuild,
  createTempBuild,
  syncCloudFlareAmpRTC,

  triggerEnsureResources,

  getNetworkResourceMapping,
  getExtGamUprPriceTargetingCandidates,

  getNetworkVirtualPlacements,
  createNetworkVirtualPlacement,
  updateNetworkVirtualPlacement,
  deleteNetworkVirtualPlacement,

  getNetworkAdvertiserAndBrands,

  getDiagnoserReport,
  getDiagnoserSymptoms,

  getPrebidSettings,
  triggerUpdatePrebidCache,

  getGamUprs,
  getGamUpr,
  updateNetworkBillableTypes,
};

async function updateNetworkBillableTypes({ params }) {
  let apiUrl = `${api}/network/${params.networkId}/update-billable-types`;
  return await postData(apiUrl, params);
}

async function triggerUpdatePrebidCache({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/trigger-update-prebid-cache`;
  return await fetchRespJson(apiUrl);
}

async function getGamUpr({ networkId, uprId }) {
  let apiUrl = `${api}/network/${networkId}/gam-upr?uprId=${uprId}`;
  return await fetchRespJson(apiUrl);
}

async function getGamUprs({ networkId, isForceRefresh }) {
  let apiUrl = `${api}/network/${networkId}/gam-upr-list`;
  if (isForceRefresh) {
    apiUrl += "?isForceRefresh=true";
  }
  return await fetchRespJson(apiUrl);
}

async function getPrebidSettings({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/prebid-settings`;
  return await fetchRespJson(apiUrl);
}

async function getDiagnoserSymptoms() {
  let apiUrl = `${api}/diagnoser-symptoms`;
  return await fetchRespJson(apiUrl);
}

async function getDiagnoserReport({ networkId, symptomName, reportDate }) {
  let apiUrl = `${api}/network/${networkId}/symptom-report?symptomName=${symptomName}&reportDate=${reportDate}`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkAdvertiserAndBrands(params) {
  let apiUrl = `${api}/network/${params.networkId}/advertiser-and-brands`;
  return await postData(apiUrl, params);
}

async function deleteNetworkVirtualPlacement(params) {
  let apiUrl = `${api}/network/${params.networkId}/delete-virtual-placement`;
  return await postData(apiUrl, params);
}

async function updateNetworkVirtualPlacement(params) {
  let apiUrl = `${api}/network/${params.networkId}/update-virtual-placement`;
  return await postData(apiUrl, params);
}

async function createNetworkVirtualPlacement(params) {
  let apiUrl = `${api}/network/${params.networkId}/create-virtual-placement`;
  return await postData(apiUrl, params);
}

async function getNetworkVirtualPlacements({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/virtual-placements`;
  return await fetchRespJson(apiUrl);
}

async function getExtGamUprPriceTargetingCandidates({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/upr-price-targeting-candidates`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkResourceMapping({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/resource-mapping`;
  return await fetchRespJson(apiUrl);
}

async function triggerEnsureResources({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/ensure-resources`;
  return await fetchRespJson(apiUrl);
}

async function syncCloudFlareAmpRTC({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/code-snippet/sync-cloudflare-amp-rtc`;
  return await postData(apiUrl, { networkId });
}

async function createNewBuild({ networkId, packageName, packageVersion }) {
  let apiUrl = `${api}/network/${networkId}/code-snippet/builds`;
  return await postData(apiUrl, { packageName, packageVersion });
}

async function createTempBuild({
  networkId,
  packageName,
  packageVersion,
  overriddenInput,
}) {
  let apiUrl = `${api}/network/${networkId}/code-snippet/temp-build`;
  return await postData(apiUrl, {
    packageName,
    packageVersion,
    overriddenInput,
  });
}

async function getBuildPackages() {
  let apiUrl = `${api}/code-snippet/packages`;
  return await fetchRespJson(apiUrl);
}

async function getBuildInput({ url }) {
  let apiUrl = url;
  return await fetchRespJson(apiUrl);
}

async function getBuildDetails({ networkId, buildId }) {
  let apiUrl = `${api}/network/${networkId}/code-snippet/${buildId}/detail`;
  return await fetchRespJson(apiUrl);
}

async function getCodeSnippetBuilds({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/code-snippet/builds`;
  return await fetchRespJson(apiUrl);
}

async function createPublisherFeatures({ pubId, appName, config }) {
  let apiUrl = `${api}/publisher/${pubId}/features`;
  return await postData(apiUrl, { appName, config });
}

async function getRawPublisherFeatures({ pubId }) {
  let apiUrl = `${api}/publisher/${pubId}/features`;
  return await fetchRespJson(apiUrl);
}

async function createNetworkFeatures({ networkId, appName, config }) {
  let apiUrl = `${api}/network/${networkId}/features`;
  return await postData(apiUrl, { appName, config });
}

async function getDefaultNetworkFeatures({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/features/default`;
  return await fetchRespJson(apiUrl);
}

async function getRawNetworkFeatures({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/features`;
  return await fetchRespJson(apiUrl);
}

async function getFeaturesSchema() {
  let apiUrl = `${api}/features/schema`;
  return await fetchRespJson(apiUrl);
}

async function getRegisteredInventorySuggestion({ networkId, params }) {
  let apiUrl = `${api}/network/${networkId}/registered-inventory-suggestion`;
  return await postData(apiUrl, { params });
}

async function getCstEstimateResource({ networkId, config }) {
  let apiUrl = `${api}/network/${networkId}/estimate-resource`;
  return await postData(apiUrl, { config });
}

async function getCustomTargetingValues({ networkId, ids }) {
  const queryString = `?ids=${ids.join(",")}`;
  let apiUrl = `${api}/network/${networkId}/custom-targeting-values${queryString}`;
  return await fetchRespJson(apiUrl);
}

async function getCustomTargetingKeys({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/custom-targeting-keys`;
  return await fetchRespJson(apiUrl);
}

async function createCstConfig(params) {
  let apiUrl = `${api}/network/${params.networkId}/cst-config`;
  return await postData(apiUrl, params);
}

async function getCstConfig({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/cst-config`;
  return await fetchRespJson(apiUrl);
}

async function getFirstLayerPriceSuggestion(params) {
  let apiUrl = `${api}/network/${params.networkId}/first-layer-prices-suggestion`;
  return await postData(apiUrl, params);
}

async function createNetworkUprSegmentation(params) {
  let apiUrl = `${api}/network/${params.networkId}/upr-segmentation`;
  return await postData(apiUrl, params);
}

async function getInventoryRegistry() {
  let apiUrl = `${api}/cst/inventory-registry`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkUprSegmentationByUnitId({ unitId }) {
  let apiUrl = `${api}/network/unit/${unitId}/upr-segmentation`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkUprSegmentation({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/upr-segmentation`;
  return await fetchRespJson(apiUrl);
}

async function getNetworkGamInfo({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/gam-info`;
  return await fetchRespJson(apiUrl);
}

async function updateNetworkAdServingCost(params) {
  let apiUrl = `${api}/update-network-ad-serving-cost`;
  return await postData(apiUrl, params);
}

async function updateNetworkCustomUpr({ networkId, customSettings }) {
  const apiUrl = `${api}/network/${networkId}/update-custom-upr`;

  // compress!!
  const compressedData = pako.gzip(JSON.stringify(customSettings));
  return await postData(apiUrl, { customSettings: compressedData });
}

async function getUnitsByNetworkId({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/units`;
  const r = await fetchRespJson(apiUrl);
  return r;
}

async function getNetworkCustomUpr({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/custom-upr`;
  const r = await fetchRespJson(apiUrl);
  return r;
}

async function getNetworksInfo({ networkIds }) {
  let apiUrl = `${api}/networks/info?networkIds=${networkIds.join(",")}`;
  const r = await fetchRespJson(apiUrl);
  return r;
}

async function getNetworkInfo({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/info`;
  const r = await fetchRespJson(apiUrl);
  return r;
}

async function getNetworkResources({ networkId }) {
  let apiUrl = `${api}/network/${networkId}/resources`;
  const r = await fetchRespJson(apiUrl);
  return r;
}
async function getNetworks(params) {
  let apiUrl = `${api}/networks`;
  return await postData(apiUrl, params);
}

async function getYieldSets(params) {
  let apiUrl = `${api}/yield-sets`;
  return await postData(apiUrl, params);
}
