import React from "react";
import _ from "lodash";
import Select from "react-select";
import ReactTooltip from "react-tooltip";

const PRESETS = {
  GOOGLE_ELIGIBLE: "Google Eligible",
  NETWORK_BILLABLE: "Network Billable",
  CUSTOM: "Custom",
};

const DEMAND_TYPES = {
  AD_EXCHANGE: "Ad Exchange",
  AD_SENSE: "AdSense",
  EXCHANGE_BIDDING: "Exchange Bidding",
  PRICE_PRIORITY: "Price Priority",
  NETWORK: "Network",
  BULK: "Bulk",
  HEADER_BIDDING: "Header Bidding",
};

class ObserverDemandTypeFilter extends React.Component {
  constructor(props) {
    super(props);

    const { demandTypePreset, demandTypes } = this.props;

    this.state = {
      selectedPreset: demandTypePreset,
      demandTypes,
    };

    this.handlePresetSelected = this.handlePresetSelected.bind(this);
    this.handleDemandTypesChanged = this.handleDemandTypesChanged.bind(this);
    this.handleRecalculate = this.handleRecalculate.bind(this);
  }

  handlePresetSelected(preset) {
    this.setState({ selectedPreset: preset });
    this.props.handleDemandTypesChanged(preset);
  }

  handleDemandTypesChanged(demandTypes) {
    this.setState({ demandTypes });
  }

  handleRecalculate() {
    const preset = this.state.selectedPreset;
    const demandTypes = this.state.demandTypes;
    this.props.handleDemandTypesChanged(preset, demandTypes);
  }

  render() {
    const { selectedPreset, demandTypes } = this.state;

    return (
      <div className="p-4">
        <div className="font-semibold text-gray-700 mb-1">Demand Types:</div>
        <div className="flex justify-start items-center mb-2 text-sm">
          <div>
            {_.values(PRESETS).map((preset) => {
              // return <div key={preset}>{preset}</div>;

              return (
                <div key={preset} className="inline-block">
                  <button
                    disabled={selectedPreset === preset}
                    type="button"
                    className={`px-4 py-1 mr-1 font-semibold rounded text-gray-700 ${
                      selectedPreset === preset
                        ? "bg-blue-200"
                        : "bg-gray-100 hover:text-gray-800 hover:bg-gray-200"
                    }`}
                    onClick={() => this.handlePresetSelected(preset)}
                    data-tip
                    data-for={`"preset-${preset}`}
                  >
                    {preset}
                  </button>

                  {preset === PRESETS.NETWORK_BILLABLE && (
                    <ReactTooltip
                      id={`"preset-${PRESETS.NETWORK_BILLABLE}`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      {this.props.billableDemandTypes.join(", ")}
                    </ReactTooltip>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="w-2/3">
            <DemandTypesSelector
              selectedDemandTypes={demandTypes}
              isDisabled={selectedPreset !== PRESETS.CUSTOM}
              handleDemandTypesChanged={this.handleDemandTypesChanged}
            ></DemandTypesSelector>
          </div>
          <div className="w-1/3">
            {selectedPreset === PRESETS.CUSTOM && (
              <button
                type="button"
                onClick={this.handleRecalculate}
                className="px-4 py-2 mr-1 font-semibold rounded text-gray-900 bg-gray-300 hover:text-gray-800 hover:bg-gray-400"
              >
                Recalculate
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

class DemandTypesSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    const typeOptions = _.values(DEMAND_TYPES);
    const options = _.map(typeOptions, (c) => {
      return { value: c, label: c };
    });

    const selectedValue = _.filter(options, (option) => {
      return _.indexOf(props.selectedDemandTypes, option.value) !== -1;
    });

    this.state = {
      options,
      selectedValue, // multi
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const types = _.map(option, (o) => {
      return o.value;
    });
    this.props.handleDemandTypesChanged(types);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        {/* <div className="inline-flex items-center px-4 py-2 border border-gray-400 rounded hover:shadow text-sm text-gray-700 bg-white cursor-pointer focus:outline-none">
            <span className="mr-2">USD</span>
            <IoIosArrowDown></IoIosArrowDown>
          </div> */}
        {/* {JSON.stringify(selectedValue)} */}
        <Select
          // className="w-24 z-50"
          // defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          // isSearchable={false}
          isMulti={true}
          isDisabled={this.props.isDisabled}
          isClearable={!this.props.isDisabled}
        />
      </>
    );
  }
}

export default ObserverDemandTypeFilter;
