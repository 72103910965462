import React from "react";
import _ from "lodash";
import Select from "react-select";
import { NetworkAPI } from "apis";

// can be multiselect
class InventoryRegistrySelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    this.state = {
      options: null,
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    try {
      const items = await NetworkAPI.getInventoryRegistry();

      const options = _.map(items, (c) => {
        return { value: c.id, label: `${c.id} (${c.label})` };
      });

      const { selectedItems } = this.props;
      const selectedValue = _.filter(options, (option) => {
        return _.indexOf(selectedItems, option.value) !== -1;
      });

      this.setState({
        options,
        selectedValue,
      });
    } catch (err) {
      console.log("error querying inventory registry");
    }
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        {options && (
          <Select
            // className="w-24 z-50"
            defaultValue={selectedValue}
            value={selectedValue}
            onChange={this.handleChange}
            options={options}
            isSearchable={true}
            isMulti={true}
            // styles={{
            //   control: () => ({
            //     paddingLeft: 3,
            //   }),
            // }}
          />
        )}
      </>
    );
  }
}

export default InventoryRegistrySelector;
