import React from "react";
import _ from "lodash";

import { NetworkAPI } from "apis";
import LoadingUI from "../../../common/LoadingUI";
import NetworkInfoHeader from "../../common/NetworkInfoHeader";
import DateTimeFormatter from "components/common/DateTimeFormatter";
import BuildDetailsModal from "./BuildDetailsModal";
import { buttonConfirmClass } from "helpers/StyleClass";
import CreateBuildModal from "./CreateBuildModal";
import SyncResultModal from "./SyncResultModal";
import TempBuildModal from "./TempBuildModal";

class CodeSnippetViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      items: null,

      isDetailModalOpen: false,
      currentBuildId: null,
      currentBuild: null,

      isAddModalOpen: false,

      isSyncModalOpen: false,
      isSyncing: false,
      syncResult: null,

      isTempBuildModalOpen: false,
    };

    this.handleOpenDetails = this.handleOpenDetails.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);

    this.handleOpenAddModal = this.handleOpenAddModal.bind(this);
    this.handleCreateBuild = this.handleCreateBuild.bind(this);

    this.handleOpenTempBuildModal = this.handleOpenTempBuildModal.bind(this);
    this.handleCreateTempBuild = this.handleCreateTempBuild.bind(this);

    this.handleRefreshAfterBuild = this.handleRefreshAfterBuild.bind(this);
    this.handleSyncCloudflare = this.handleSyncCloudflare.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      if (!networkId) {
        throw new Error("Missing Network ID");
      }
      const networkInfo = await NetworkAPI.getNetworkInfo({ networkId });
      if (!networkInfo) {
        throw new Error("Invalid Network");
      }

      document.title = `${networkId} Network Code Snippet | YB Observer`;

      // const { buildHistory } = await NetworkAPI.getCodeSnippetBuilds({
      //   networkId,
      // });
      await this.queryBuilds(networkId);

      this.setState({
        networkId,
        networkInfo,

        // items: _.orderBy(buildHistory, ["createdAt"], ["desc"]),

        isLoading: false,
        errMsg: null,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  async queryBuilds(networkId) {
    const { buildHistory } = await NetworkAPI.getCodeSnippetBuilds({
      networkId,
    });

    this.setState({
      items: _.orderBy(buildHistory, ["createdAt"], ["desc"]),
    });
  }

  handleModalClose() {
    this.setState({
      isDetailModalOpen: false,
      currentBuild: null,
      currentBuildId: null,
      isAddModalOpen: false,
      isSyncModalOpen: false,
      syncResult: null,
      isTempBuildModalOpen: false,
    });
  }

  handleOpenDetails(item) {
    this.setState({
      isDetailModalOpen: true,
      currentBuildId: item.buildId,
      currentBuild: item,
    });
  }

  handleOpenAddModal() {
    this.setState({
      isAddModalOpen: true,
    });
  }

  handleOpenTempBuildModal() {
    this.setState({
      isTempBuildModalOpen: true,
    });
  }

  async handleCreateBuild({ packageName, packageVersion }) {
    const { networkId } = this.state;
    return await NetworkAPI.createNewBuild({
      networkId,
      packageName,
      packageVersion,
    });
  }

  async handleCreateTempBuild({
    packageName,
    packageVersion,
    overriddenInput,
  }) {
    const { networkId } = this.state;
    return await NetworkAPI.createTempBuild({
      networkId,
      packageName,
      packageVersion,
      overriddenInput,
    });
  }

  async handleRefreshAfterBuild() {
    this.handleModalClose();
    await this.queryBuilds(this.state.networkId);
  }

  async handleSyncCloudflare() {
    const userConfirm = window.confirm(`Sync Cloudflare AMP RTC?`);
    if (!userConfirm) return;

    this.setState({
      isSyncModalOpen: true,
      isSyncing: true,
    });
    try {
      const { networkId } = this.state;
      const results = await NetworkAPI.syncCloudFlareAmpRTC({ networkId });

      this.setState({
        isSyncing: false,
        syncResult: JSON.stringify(results, null, 2),
      });
    } catch (err) {
      this.setState({
        isSyncing: false,
        syncResult: JSON.stringify(err, null, 2),
      });
    }
  }

  render() {
    const {
      networkId,
      networkInfo,

      items,

      isLoading,
      errMsg,

      isDetailModalOpen,
      currentBuildId,
      currentBuild,

      isAddModalOpen,

      isSyncModalOpen,
      isSyncing,
      syncResult,

      isTempBuildModalOpen,
    } = this.state;

    return (
      <div>
        {isLoading && <LoadingUI></LoadingUI>}
        {errMsg && <div className="text-red-800">{errMsg}</div>}

        {networkInfo && (
          <div>
            <NetworkInfoHeader networkInfo={networkInfo}></NetworkInfoHeader>
            <div className="min-h-screen bg-white px-12 py-8">
              <div className="mb-2 flex items-center justify-between">
                <PageTitle title="Network Code Snippet Builds"></PageTitle>
              </div>

              <div className="mb-2 flex items-center justify-end gap-4">
                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleSyncCloudflare}
                >
                  Sync Cloudflare
                </button>

                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenTempBuildModal}
                >
                  Create temp build
                </button>

                <button
                  type="button"
                  className={buttonConfirmClass}
                  onClick={this.handleOpenAddModal}
                >
                  Create new build
                </button>
              </div>

              <div>
                {items.length > 0 ? (
                  <BuildsTable
                    items={items}
                    handleOpenDetails={this.handleOpenDetails}
                  ></BuildsTable>
                ) : (
                  "No builds yet"
                )}

                {isDetailModalOpen && (
                  <BuildDetailsModal
                    isOpenViewModal={isDetailModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    buildId={currentBuildId}
                    build={currentBuild}
                  ></BuildDetailsModal>
                )}

                {isAddModalOpen && (
                  <CreateBuildModal
                    isOpenViewModal={isAddModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    handleCreateBuild={this.handleCreateBuild}
                    handleRefreshAfterBuild={this.handleRefreshAfterBuild}
                  ></CreateBuildModal>
                )}

                {isSyncModalOpen && (
                  <SyncResultModal
                    isOpenViewModal={isSyncModalOpen}
                    handleClose={this.handleModalClose}
                    isLoading={isSyncing}
                    syncResult={syncResult}
                  ></SyncResultModal>
                )}

                {isTempBuildModalOpen && (
                  <TempBuildModal
                    isOpenViewModal={isTempBuildModalOpen}
                    handleClose={this.handleModalClose}
                    networkId={networkId}
                    handleCreateTempBuild={this.handleCreateTempBuild}
                  ></TempBuildModal>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PageTitle({ title }) {
  return <div className="text-3xl font-extrabold">{title}</div>;
}

const buttonActionClass =
  "px-2 py-1 mr-1 my-2 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold";

const BuildsTable = (props) => {
  const { items, handleOpenDetails } = props;

  return (
    <div>
      <table
        className="border shadow table w-full text-sm"
        style={{ marginBottom: "500px" }}
      >
        <thead className="border-b bg-gray-200 text-xs text-blue-800">
          <tr>
            <th className="border py-1 px-2">Build ID</th>
            <th className="border py-1 px-2">Package Name</th>
            <th className="border py-1 px-2">Package Version</th>
            <th className="border py-1 px-2">Created At</th>
            <th className="border py-1 px-2">Actions</th>
          </tr>
        </thead>
        <tbody className="bg-white font-mono text-gray-900">
          {items &&
            items.map((r) => {
              return (
                <tr key={r.buildId} className="border-b hover:bg-gray-100">
                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.buildId}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.packageName}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    {r.packageVersion}
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <DateTimeFormatter
                      datetime={r.createdAt}
                    ></DateTimeFormatter>
                  </td>

                  <td className="whitespace-no-wrap border py-1 px-3 text-right">
                    <button
                      type="button"
                      className={buttonActionClass}
                      onClick={() => handleOpenDetails(r)}
                    >
                      Details
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default CodeSnippetViewer;
