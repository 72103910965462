import React from "react";
// import _ from "lodash";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import RevenueDistributionSection from "./RevenueDistributionSection";
import NumberFormat from "../../common/NumberFormat";
import { formatMoneyWithCurrency } from "./ReportsHelper";
import FadeInWrapper from "./FadeInWrapper";
import InfoDescriptionTooltip from "./InfoDescriptionTooltip";
import RightArrowLong from "../../../images/right-arrow-long.svg";
import SummaryRateGaugeChart from "./SummaryRateGaugeChart";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

function RightArrow() {
  return <img src={RightArrowLong} />;
}

class SummarySectionForCst extends React.Component {
  render() {
    const {
      summaryData,
      distributionData,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetIds,
      startDate,
      endDate,
      selectedCurrency,
    } = this.props;

    function NetRevSection() {
      return (
        <div className="border rounded shadow flex justify-center p-4">
          <div className="mb-2 flex items-end">
            <div className="text-lg font-semibold text-gray-800">
              <div className="flex items-center justify-center">
                Net Increased Revenue
                <InfoDescriptionTooltip
                  metricKey="NET_INCREASED_GOOGLE_REV"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>

              <div className="pt-1 text-5xl font-bold leading-none">
                <FadeInWrapper key={summaryData.netIncreasedRevenue}>
                  {formatMoneyWithCurrency({
                    currency: selectedCurrency,
                    value: summaryData.netIncreasedRevenue,
                  })}
                </FadeInWrapper>
              </div>
            </div>

            <div className="border-l ml-3 pl-3">
              <div
                className="flex items-center font-bold"
                style={{ color: "#ed8936" }} // orange
              >
                {summaryData.securedLift >= 0 ? (
                  <div className="pr-1 font-bold">
                    <FaRegArrowAltCircleUp></FaRegArrowAltCircleUp>
                  </div>
                ) : (
                  ""
                )}
                <FadeInWrapper key={summaryData.securedLift}>
                  {summaryData.securedLift >= 0 ? "+" : "-"}
                  {Math.abs(summaryData.securedLift)}%
                </FadeInWrapper>
              </div>

              <div className="flex items-center text-xs text-gray-600">
                Optimized Lift
                <InfoDescriptionTooltip
                  metricKey="OPTIMIZED_LIFT"
                  place="top"
                ></InfoDescriptionTooltip>
              </div>
            </div>
          </div>
        </div>
      );
    }

    function ReqFunnelSection() {
      return (
        <div className="border rounded shadow p-4">
          <div className="mb-4 text-center text-lg font-semibold text-gray-800">
            Request Funnel
          </div>

          <div className="grid grid-cols-5 gap-2">
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Supported
                  <InfoDescriptionTooltip
                    metricKey="SUPPORTED_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.totalRequests}>
                    <NumberFormat
                      value={summaryData.totalRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center text-gray-600">
              <RightArrow></RightArrow>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="flex items-center text-center text-sm font-semibold leading-tight text-gray-700">
                  A/B Testing Tagged
                  <InfoDescriptionTooltip
                    metricKey="AB_TESTING_TAGGED_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.protectedRequests}>
                    <NumberFormat
                      value={summaryData.protectedRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center text-gray-600">
              <RightArrow></RightArrow>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col items-center px-2">
                <div className="flex items-center text-sm font-semibold text-gray-700">
                  Optimized
                  <InfoDescriptionTooltip
                    metricKey="OPTIMIZED_REQ"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-lg font-semibold text-gray-900">
                  <FadeInWrapper key={summaryData.boostingRequests}>
                    <NumberFormat
                      value={summaryData.boostingRequests}
                      format="0,0.00a"
                    ></NumberFormat>
                  </FadeInWrapper>
                </div>
              </div>
            </div>

            <div className="col-span-1"></div>

            <div className="col-span-1 col-start-2 row-start-2">
              <div
                className="flex flex-col items-center py-2"
                style={{ marginTop: "-24px" }}
              >
                <div className="text-sm text-gray-800">
                  <FadeInWrapper key={summaryData.protectedRate}>
                    <SummaryRateGaugeChart
                      value={summaryData.protectedRate}
                    ></SummaryRateGaugeChart>
                  </FadeInWrapper>
                </div>

                <div className="flex items-center text-center text-xs leading-tight text-gray-600">
                  <span className="-ml-3">A/B Testing Tagged Ratio</span>
                  <InfoDescriptionTooltip
                    metricKey="AB_TESTING_TAGGED_RATE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
              </div>
            </div>

            <div className="col-span-1 col-start-4 row-start-2">
              <div
                className="flex flex-col items-center py-2"
                style={{ marginTop: "-24px" }}
              >
                <div className="text-sm text-gray-800">
                  <FadeInWrapper key={summaryData.defendedRate}>
                    <SummaryRateGaugeChart
                      value={summaryData.defendedRate}
                    ></SummaryRateGaugeChart>
                  </FadeInWrapper>
                </div>
                <div className="flex items-center text-center text-xs text-gray-600">
                  <span className="-ml-3">Optimized Ratio</span>
                  <InfoDescriptionTooltip
                    metricKey="OPTIMIZED_RATE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={fixedWidthStyle}>
        <div className="mb-4 text-2xl font-bold">Summary</div>
        <div className="flex">
          <div className="mr-8 w-3/5">
            <div className="mb-6">
              <NetRevSection></NetRevSection>
            </div>
            <div>
              <ReqFunnelSection></ReqFunnelSection>
            </div>
          </div>

          <div className="w-2/5">
            <div className="border shadow rounded">
              <RevenueDistributionSection
                selectedPubId={selectedPubId}
                selectedNetworkId={selectedNetworkId}
                selectedYieldSetIds={selectedYieldSetIds}
                startDate={startDate}
                endDate={endDate}
                distributionData={distributionData}
                selectedCurrency={selectedCurrency}
              ></RevenueDistributionSection>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SummarySectionForCst;
