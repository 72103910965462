import React from "react";
import _ from "lodash";
import Select from "react-select";
import { GAM_DEVICE_CATEGORY } from "../../constants/GAMDeviceCategory";

// can be multiselect
class DeviceCategorySelector extends React.PureComponent {
  constructor(props) {
    super(props);
    // props:
    // selectedItems
    // handleChanged

    const { selectedItems } = props;
    const allCategories = _.map(_.keys(GAM_DEVICE_CATEGORY), (c) => {
      return {
        id: c,
        name: GAM_DEVICE_CATEGORY[c],
      };
    });
    const options = _.map(allCategories, (c) => {
      return { value: c.id, label: c.name };
    });

    const selectedValue = _.filter(options, (option) => {
      return _.indexOf(selectedItems, option.value) !== -1;
    });

    this.state = {
      options,
      selectedValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(options) {
    this.setState({
      selectedValue: options,
    });

    this.props.handleChanged(_.map(options, "value"));
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          // className="w-24 z-50"
          defaultValue={selectedValue}
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
          isSearchable={true}
          isMulti={true}
          // styles={{
          //   control: () => ({
          //     paddingLeft: 3,
          //   }),
          // }}
        />
      </>
    );
  }
}

export default DeviceCategorySelector;
