import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";
import SetOfAdSizesFormatter from "./SetOfAdSizesFormatter";
import BatchOfSegmentsFormatter from "./BatchOfSegmentsFormatter";
import CstPredictScheduleFormatter from "./CstPredictScheduleFormatter";

class ConfigValueReadonly extends React.PureComponent {
  render() {
    const {
      valueType,
      configValue,
      //valueSchema
    } = this.props;

    if (valueType === "boolean") {
      return configValue === true ? "true" : "false";
    }

    if (valueType === "ad_sizes") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    if (valueType === "ad_sizes_layers") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    if (valueType === "sets_of_ad_sizes") {
      return (
        <SetOfAdSizesFormatter
          setsOfAdSizes={configValue}
          showActionButtons={false}
        ></SetOfAdSizesFormatter>
      );
    }

    if (valueType === "batch_of_segments") {
      return (
        <BatchOfSegmentsFormatter
          includeBatchKey={true}
          batchSegments={configValue}
          showActionButtons={false}
        ></BatchOfSegmentsFormatter>
      );
    }

    if (valueType === "upr_prices") {
      if (!configValue) return "None";

      if (_.isArray(configValue)) {
        return (
          <div className="text-sm">
            {configValue &&
              configValue.map((p, i) => {
                return (
                  <div key={i}>
                    Price: {p.price}, Weight: {p.weight}
                  </div>
                );
              })}
          </div>
        );
      }
    }

    if (valueType === "price_groups") {
      if (!configValue || _.isEmpty(configValue)) return "None";

      if (_.isArray(configValue)) {
        return (
          <div className="text-sm">
            {configValue &&
              configValue.map((p, i) => {
                return (
                  <div key={i}>
                    inclusiveMin: {p.inclusiveMin}, exclusiveMax:{" "}
                    {p.exclusiveMax}, delta: {p.delta}
                  </div>
                );
              })}
          </div>
        );
      }
    }

    if (valueType === "cst_algorithms") {
      if (!configValue) return "None";

      if (_.isArray(configValue)) {
        const labelClass = "leading-tight";
        const valueClass = "leading-tight text-gray-700";
        return (
          <div className="mb-1 text-sm">
            {configValue &&
              configValue.map((item, i) => {
                return (
                  <div key={i} className="border rounded border-gray-400 p-2">
                    <div className="mb-2">
                      <div className={labelClass}>Name:</div>
                      <div className={valueClass}>{item.name}</div>
                    </div>

                    <div className="mb-2">
                      <div className={labelClass}>Algorithm:</div>
                      <div className={valueClass}>{item.algorithm}</div>
                    </div>

                    <div>
                      <div className={labelClass}>Params:</div>
                      <div className={valueClass}>
                        <div>{JSON.stringify(item.params, null, 4)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        );
      }
    }

    if (valueType === "cst_predict_schedule") {
      // return JSON.stringify(configValue);
      return (
        <CstPredictScheduleFormatter
          configValue={configValue}
        ></CstPredictScheduleFormatter>
      );
    }

    if (valueType === "rsa_object") {
      if (!configValue || _.isEmpty(configValue)) {
        return "None";
      }

      return (
        <div>
          <div>Enabled: {configValue.enabled ? "true" : "false"}</div>
          <div>Reportable Type: {configValue.reportableType}</div>
        </div>
      );
    }

    if (valueType === "device_categories") {
      return _.isArray(configValue) ? configValue.join(", ") : "None";
    }

    if (valueType === "client_refresh_labels") {
      return _.isArray(configValue) && _.isEmpty(configValue)
        ? "[]"
        : configValue.join(", ");
    }

    if (valueType === "demand_types") {
      if (typeof configValue === "string") {
        return configValue;
      }

      return _.isArray(configValue) && _.isEmpty(configValue)
        ? "[]"
        : configValue.join(", ");
    }

    if (
      valueType === "integer" ||
      valueType === "float" ||
      valueType === "integer_enum"
    ) {
      // careful of 0
      return _.isUndefined(configValue) ? "None" : configValue;
    }

    if (
      valueType === "array_string" ||
      valueType === "array_integer" ||
      valueType === "array_float"
    ) {
      return _.isArray(configValue) && _.isEmpty(configValue) ? (
        "None"
      ) : _.isArray(configValue) ? (
        <div>
          <div className="font-mono font-normal">{configValue.join(", ")}</div>
          {configValue.length > 1 && (
            <div className="text-sm">({configValue.length} items)</div>
          )}
        </div>
      ) : (
        configValue || "None"
      );
    }

    // "string_enum"
    return configValue || "None";
  }
}

export default ConfigValueReadonly;
