import React from "react";
import _ from "lodash";
import queryString from "query-string";
import LoadingUI from "../common/LoadingUI";
import { DashboardAPI, NetworkAPI } from "apis";
import HomeDashboardWrapperV1 from "./home-dashboard-v1/HomeDashboardWrapperV1";
import HomeDashboardWrapperV2 from "./home-dashboard-v2/HomeDashboardWrapperV2";
import HomeDashboardWrapperCST from "./home-dashboard-v2/HomeDashboardWrapperCST";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class HomeDashboardViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      pubId: null,
      isReseller: false,
      resultData: null,
      // Important! Dashboard version v1 and v2 has different dashboard body
      version: null,

      isHistoryDateRange: true, // to see ad hoc run reports of historical date ranges
    };

    this.handleChangeVersion = this.handleChangeVersion.bind(this);
    this.handleCheckTempReport = this.handleCheckTempReport.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const pubId = _.get(this.props, "match.params.pubId");
    document.title = `${pubId} Home Dashboard | YB Observer`;

    const isReseller = _.startsWith(
      _.get(this.props, "location.pathname"),
      "/playground/console-overview-mockup-reseller"
    );

    let isHistoryDateRange = false;
    const urlQueryParams = queryString.parse(this.props.location.search);
    if (urlQueryParams.isTemp && urlQueryParams.isTemp === "true") {
      isHistoryDateRange = true;
    }

    try {
      let resultData = null;

      if (!isHistoryDateRange) {
        resultData = await DashboardAPI.getHomeDashboardData(pubId, "v2");
      } else {
        resultData = await DashboardAPI.getHomeDashboardDataTemp();
      }
      if (!resultData) {
        throw new Error("No Data");
      }
      const version = _.get(resultData, "version", "v1");
      console.log(resultData);

      const networkIds = _.map(resultData.gamNetworks, "id");
      const networksInfo = await NetworkAPI.getNetworksInfo({ networkIds });
      console.log(networksInfo);
      const isVersionCst = _.every(networksInfo, (n) => {
        return n.product > 1; // 1: DT
      });
      console.log("is cst?", isVersionCst);

      this.setState({
        resultData,
        isReseller,
        pubId,
        version,
        isVersionCst,
        isHistoryDateRange,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  async handleChangeVersion() {
    this.setState({ isLoading: true });
    const newVersion = this.state.version === "v1" ? "v2" : "v1";

    try {
      const resultData = await DashboardAPI.getHomeDashboardData(
        this.state.pubId,
        newVersion
      );
      if (!resultData) {
        throw new Error("No Data");
      }
      const version = _.get(resultData, "version", "v1");
      console.log(resultData);
      this.setState({
        resultData,
        version,
        isHistoryDateRange: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  async handleCheckTempReport() {
    this.setState({ isLoading: true });

    const { isHistoryDateRange, pubId } = this.state;
    try {
      let resultData = null;
      // const resultData = await DashboardAPI.getHomeDashboardDataTemp();
      if (isHistoryDateRange) {
        resultData = await DashboardAPI.getHomeDashboardData(pubId, "v2");
        this.props.history.push({
          search: "",
        });
      } else {
        resultData = await DashboardAPI.getHomeDashboardDataTemp();
        this.props.history.push({
          search: "?isTemp=true",
        });
      }
      if (!resultData) {
        throw new Error("No Data");
      }
      const version = _.get(resultData, "version", "v1");
      console.log(resultData);
      this.setState({
        resultData,
        version,
        isHistoryDateRange: !isHistoryDateRange,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,

      resultData,
      isReseller,
      pubId,
      version,
      isVersionCst,

      isHistoryDateRange,
    } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-white">{errMsg}</div>;
    }

    return (
      <>
        <div className="border-bottom border bg-white px-12">
          <h1 className="pt-8 pb-2" style={fixedWidthStyle}>
            <div className="text-base font-bold leading-none text-gray-600">
              Intowow Console
            </div>
            <div className="flex items-center">
              <div className="mr-4 flex items-center text-4xl font-extrabold text-gray-900">
                {isReseller ? "Reseller" : ""} Home Dashboard {version}
                <div className="px-4 text-sm">
                  <button
                    className="text-blue-600 hover:text-blue-700 hover:underline"
                    onClick={this.handleChangeVersion}
                  >
                    Switch to {version === "v1" ? "v2" : "v1"}
                  </button>
                </div>
                {pubId === "272" && version === "v2" && (
                  <div className="px-4 text-sm">
                    {isHistoryDateRange ? (
                      <button
                        className="text-blue-600 hover:text-blue-700 hover:underline"
                        onClick={this.handleCheckTempReport}
                      >
                        Back to recent 3 months
                      </button>
                    ) : (
                      <button
                        className="text-blue-600 hover:text-blue-700 hover:underline"
                        onClick={this.handleCheckTempReport}
                      >
                        Jan to March Report
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </h1>
        </div>

        {isVersionCst ? (
          <HomeDashboardWrapperCST
            resultData={resultData}
            isReseller={isReseller}
            pubId={pubId}
            version={version}
            isHistoryDateRange={isHistoryDateRange}
          ></HomeDashboardWrapperCST>
        ) : (
          <>
            {version === "v1" && (
              <HomeDashboardWrapperV1
                resultData={resultData}
                isReseller={isReseller}
                pubId={pubId}
                version={version}
                isHistoryDateRange={isHistoryDateRange}
              ></HomeDashboardWrapperV1>
            )}

            {version === "v2" && (
              <HomeDashboardWrapperV2
                resultData={resultData}
                isReseller={isReseller}
                pubId={pubId}
                version={version}
                isHistoryDateRange={isHistoryDateRange}
              ></HomeDashboardWrapperV2>
            )}
          </>
        )}
      </>
    );
  }
}

export default HomeDashboardViewer;
